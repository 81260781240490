/* eslint-disable */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { showPriceValue } from "../Helpers/SettingHelper";
import cookie from "react-cookies";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import tickImg from "../../common/images/tick.png";

class Thankyou extends Component {
  constructor(props) {
    super(props);
    this.state = { pay_orderid: "", pay_amount: 0, status: "loading" };

    cookie.remove("cateringPay");
    cookie.remove("orderIdTxt");
    cookie.remove("paymentIdTxt");
  }

  componentDidMount() {
    var userIdTxt =
      typeof cookie.load("UserId") === "undefined" ? "" : cookie.load("UserId");
    var payOrderid =
      typeof cookie.load("payOrderid") === "undefined"
        ? ""
        : cookie.load("payOrderid");
    var payAmount =
      typeof cookie.load("payAmount") === "undefined"
        ? ""
        : cookie.load("payAmount");

    if (userIdTxt !== "" && payOrderid !== "") {
      cookie.remove("payOrderid");
      cookie.remove("payAmount");
      this.setState({
        status: "ok",
        pay_orderid: payOrderid,
        pay_amount: payAmount,
      });
    }

    var pay_orderid = this.state.pay_orderid;
    if (userIdTxt === "" || (payOrderid === "" && pay_orderid === "")) {
      this.props.history.push("/");
    }
  }

  render() {
    return (
      <div>
        {/* Header start */}
        <Header />
        {/* Header End */}

        {/* catering-payment-page - start */}
        <div className="container common-top-div catering-thankyou-page">
          {/* innersection_wrap - start */}
          <div className="innersection_wrap tnk-you">
            <div className="mainacc_toptext tick make-pay-div">
              <img src={tickImg} />
              <h2>Thank You </h2>
              <p>Your catering paymemt has been paid successfully</p>
            </div>

            {/* order-detail-maindiv - start */}
            <div className="thank-order-detaildiv">
              <div className="tnk-detail text-center">
                <h2>YOUR PAYMENT DETAILS</h2>
                <div className="tnk-order">
                  <h3>Order No - {this.state.pay_orderid}</h3>
                  <h4>
                    Catering Paid Amount -{" "}
                    {showPriceValue(this.state.pay_amount)}
                  </h4>
                </div>
              </div>

              <div className="tnk-chk-order">
                <Link to={"/myorders"} className="button">
                  Go To My Account
                </Link>
              </div>
            </div>
            {/* order-detail-maindiv - end */}
          </div>
          {/* innersection_wrap - end */}
        </div>
        {/* payment-main-div - end */}

        <Footer />
      </div>
    );
  }
}

export default withRouter(Thankyou);
