/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Slider from "react-slick";

import { stripslashes,showAlert } from "../Helpers/SettingHelper";

import { GET_MENU_NAVIGATION } from "../../actions";

import drinkImg from "../../common/images/drink.jpg";
import foodImg from "../../common/images/food.jpg";
import cookie from "react-cookies";

class MenuNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigateMenu: [],
      categorylist: [],
      categoryimagesource: "",
      subcategorylist: [],
      subcategoryimagesource: "",
      navigateMenu: [],
      selectedNavigation: "",

      selectedsubNavigation: "",
    };

  }

  componentDidMount() {
    /* Mobile header menu */
    $(".hcategory_trigger").click(function() {
      $(this).toggleClass("active");
      $(".hcategory_menu").toggleClass("open");
    });

    $(document).click(function(e) {
      if (!$(e.target).is(".hcategory_trigger")) {
        if ($(".hcategory_menu").hasClass("open")) {
          $(".hcategory_menu").removeClass("open");
          $(".hcategory_trigger").removeClass("active");
        }
      }
    });


  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedsubNavigation != this.props.selectedsubNavigation) {
      console.log(nextProps.selectedsubNavigation, this.props.selectedsubNavigation, 'menu navi initial seetup');

      this.setState({
        selectedsubNavigation: nextProps.selectedsubNavigation,
        selectedNavigation: nextProps.selectedNavigation,
      });
      this.props.sateValChange("subcategory", nextProps.selectedsubNavigation);
    }
  }

  navigateMenuListcat(deviceview = "Desktop") {
    let navigateMenuArr = this.props.navigateMenu;

    // let navigateMenuArr = this.state.categorylist;

    let categoryimagesource =
      this.props.navigateCommon.category_image_url + "/";
    let subcategoryimagesource = this.state.subcategoryimagesource;

    let selectedNav = this.props.selectedNavigation;

    let mainMenu = "";

    if (Object.keys(navigateMenuArr).length > 0) {
      if (deviceview == "Desktop") {
        mainMenu = navigateMenuArr.map((loaddata, index) => {
          if (loaddata.menu_type == "main") {
            return (
              <div
                key={index + 1}
                className={
                  selectedNav === loaddata.pro_cate_slug
                    ? "bakery_slide active"
                    : "bakery_slide"
                }
              >
                <p>
                  <Link
                    to={"/products/category/" + loaddata.pro_cate_slug}
                    onClick={this.getsubcatlist.bind(this, loaddata)}
                    title={loaddata.menu_custom_title}
                  >
                    <img
                      src={
                        loaddata.pro_cate_image
                          ? categoryimagesource + loaddata.pro_cate_image
                          : drinkImg
                      }
                    />
                    <span>{stripslashes(loaddata.menu_custom_title)}</span>
                  </Link>
                </p>
              </div>
            );
          }
        });
      } else {
        mainMenu = navigateMenuArr.map((loaddata, index) => {
          if (loaddata.menu_type == "main") {
            return (
              <li
                key={index + 1}
                className={
                  selectedNav === loaddata.pro_cate_slug ? "active" : ""
                }
              >
                <Link
                  to="/products"
                  onClick={this.getsubcatlist.bind(this, loaddata)}
                  title={loaddata.menu_custom_title}
                >
                  {stripslashes(loaddata.menu_custom_title)}
                </Link>
              </li>
            );
          }
        });
      }

      return mainMenu;
    } else {
      return "";
    }


  }

  getsubcatlist(loaddata) {
    let prev_state_nav = this.state.selectedNavigation;

    if (prev_state_nav != loaddata.pro_cate_slug) {
      this.setState({ selectedNavigation: loaddata.pro_cate_slug }, () => {
        this.props.getMenuNavigationList(
          loaddata.menu_category_id,
          loaddata.pro_cate_slug,
          loaddata.menu_custom_title
        );
      });

      //console.log(prev_state_nav,loaddata.pro_cate_slug,'changed');
    } else {
      //console.log(prev_state_nav,loaddata.pro_cate_slug,'not changed');
    }
  }

  navigateMobileMenuList() {
    let navigateMenuArr = this.props.sub_navigateMenu;
    let selectedNav = this.state.selectedsubNavigation;

    if (Object.keys(navigateMenuArr).length > 0) {
      const mainMenu = navigateMenuArr.map((loaddata, index) => {
        return (
          <div
            key={index + 1}
            className={
              selectedNav === loaddata.pro_subcate_slug
                ? "menu-list-intvl active"
                : "menu-list-intvl"
            }
          >
            <Link
              to={"/#" + loaddata.pro_subcate_slug}
              onClick={(e) => {
                e.preventDefault();
                this.goCategoryProducts(
                  loaddata.pro_subcate_slug,
                  loaddata.pro_subcate_id
                );
              }}
              className="menu-title-link"
              title={loaddata.pro_subcate_name}
            >
              {stripslashes(loaddata.pro_subcate_name.toLowerCase())}
            </Link>
          </div>
        );
      });

      return mainMenu;
    } else {
      return;
    }
  }

  navigateMenuList() {
    let navigateMenuArr = this.props.sub_navigateMenu;
    let selectedNav = this.state.selectedsubNavigation;

    if (Object.keys(navigateMenuArr).length > 0) {
      const mainMenu = navigateMenuArr.map((loaddata, index) => {
        if (index <= 4) {
          return (
            <div
              key={index + 1}
              className={
                selectedNav === loaddata.pro_subcate_slug
                  ? "menu-list-intvl active"
                  : "menu-list-intvl"
              }
            >
              <Link
                to={"/#" + loaddata.pro_subcate_slug}
                onClick={(e) => {
                  e.preventDefault();
                  this.goCategoryProducts(
                    loaddata.pro_subcate_slug,
                    loaddata.pro_subcate_id
                  );
                }}
                className="menu-title-link"
                title={loaddata.pro_subcate_name}
              >
                {stripslashes(loaddata.pro_subcate_name.toLowerCase())}
              </Link>
            </div>
          );
        }
      });
      setTimeout(function() {
        $(window).scroll(function() {
          var scrollPos = $(document).scrollTop();
          $(".product-menu .menu-list-intvl a")
            .each(function() {
              var currLink = $(this);
              var refElement = $(currLink.attr("href").replace(/\//g, ""));
              if (
                refElement.position() !== "" &&
                typeof refElement.position() !== undefined &&
                typeof refElement.position() !== "undefined"
              ) {
                var toppos = refElement.position().top - 320;

                if (
                  toppos <= scrollPos &&
                  parseFloat(toppos) + parseFloat(refElement.height()) <
                    scrollPos
                ) {
                  $(".more-menu-parent, .more_categor_info").removeClass(
                    "active"
                  );
                  $(".product-menu .menu-list-intvl").removeClass("active");
                  currLink.parents(".menu-list-intvl").addClass("active");
                } else {
                  currLink.parents(".menu-list-intvl").removeClass("active");
                }
              }
            })
            .promise()
            .done(function() {
              if ($(".menu-list-intvl.active").length === 0) {
                $(".product-menu .menu-list-intvl:first-child").addClass(
                  "active"
                );
              }
            });
        });
      }, 100);
      return mainMenu;
    } else {
      return;
    }
  }

  navigateMenuListHidden() {
    let navigateMenuArr = this.props.sub_navigateMenu;
    let selectedNav = this.state.selectedsubNavigation;

    var i = 0;
    if (Object.keys(navigateMenuArr).length > 0) {
      const mainMenu = navigateMenuArr.map((loaddata, index) => {
        if (index > 4) {
          i++;

          return (
            <li
              key={index + 1}
              className={
                selectedNav === loaddata.pro_subcate_slug
                  ? "menu-list-intvl active"
                  : "menu-list-intvl"
              }
            >
              <Link
                to={"/#" + loaddata.pro_subcate_slug}
                className="menu-title-link"
                onClick={(e) => {
                  e.preventDefault();
                  this.goCategoryProducts(
                    loaddata.pro_subcate_slug,
                    loaddata.pro_subcate_id
                  );
                }}
                title={loaddata.pro_subcate_name}
              >
                {stripslashes(loaddata.pro_subcate_name.toLowerCase())}
              </Link>
            </li>
          );
        }
      });
      setTimeout(function() {
        $(window).scroll(function() {
          var scrollPos = $(document).scrollTop();
          $(".more-menu ul li a")
            .each(function() {
              var currLink = $(this);
              var refElement = $(currLink.attr("href").replace(/\//g, ""));

              if (
                refElement.position() !== "" &&
                typeof refElement.position() !== undefined &&
                typeof refElement.position() !== "undefined"
              ) {
                var toppos = refElement.position().top - 320;

                if (
                  toppos <= scrollPos &&
                  parseFloat(toppos) + parseFloat(refElement.height()) <
                    scrollPos
                ) {
                  $(".more-menu ul li").removeClass("active");
                  $(".product-menu .menu-list-intvl").removeClass("active");
                  $(".more-menu-parent, .more_categor_info").addClass("active");
                  currLink.parents("li").addClass("active");
                } else {
                  currLink.parents("li").removeClass("active");
                }
              }
            })
            .promise()
            .done(function() {
              if ($(".more-menu .menu-list-intvl.active a").length > 0) {
                $(".more-menu .more-menu-name").html(
                  $(".more-menu .menu-list-intvl.active a").text()
                );
              } else {
                $(".more-menu-parent, .more_categor_info").removeClass(
                  "active"
                );
                $(".more-menu .more-menu-name").html("MORE");
              }
            });
        });
      }, 100);

      if (i > 0) {
        $(".more-menu").show();
      } else {
        $(".more-menu").hide();
      }
      return mainMenu;
    } else {
      return;
    }
  }

  goCategoryProducts(subcategory_slug, subcategory_id) {
    this.setState({ selectedsubNavigation: subcategory_slug });
    this.props.sateValChange("subcategory", subcategory_slug);

    if ($("#pro_cat_" + subcategory_id).length) {
      $("html, body").animate(
        { scrollTop: $("#pro_cat_" + subcategory_id).offset().top - 270 },
        1000
      );
    }

    $(".more-menu-parent, .more_categor_info").removeClass("active");
    $(".more_categor_info").hide();
  }

  render() {
    var settingsGallery = {
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1191,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
          },
        },
      ],
    };

    return (
      <>
        <div className="hcategory_sec">
          <a href={void 0} className="hcategory_selected_text">
            {this.props.selectedCatry}
            <span className="hcategory_trigger">View More</span>
          </a>
          <div className="hcategory_menu">
            <ul className="hcategory_menulist">
              {this.navigateMenuListcat("Mobile")}
            </ul>
          </div>
        </div>

        <div className="menu-section_full">
          <div className="bakery">
            <div className="container-one">
              <div className="bakery_row">
                <div className="bakery_slider">
                  {this.navigateMenuListcat()}
                </div>
              </div>
            </div>
          </div>

          <div className="menu-section-mobile-inner hidden">
            <figure>
              <Slider {...settingsGallery}>
                {this.navigateMobileMenuList()}
              </Slider>
            </figure>
          </div>
        </div>
        <div className="menu-section-left">
          <div className="menu-section-left-inner product-menu">
            {this.navigateMenuList()}
          </div>
          <div
            className="more-menu open_more_categories"
            style={{ display: "none" }}
          >
            <div className="more-menu-parent">
              <span className="more-menu-name">MORE</span>
              <label className="open_more_category">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                  viewBox="3.75 7.5 16.5 9"
                >
                  {" "}
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  ></path>{" "}
                </svg>{" "}
              </label>
            </div>
            <ul style={{ display: "none" }} className="more_categor_info">
              {this.navigateMenuListHidden()}
            </ul>
          </div>
        </div>
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var tempArr = Array();
  var menu_slug = "";
  var menu_cat_id = "";
  var menu_type = "";
  var navigateRst = Array();
  var navigateCmn = Array();
  var selectedCatry = "";

  let sub_navigateRst = Array();
  let sub_selectedCatry = "";
  let selectedsubNavigation = "";
  let sub_navigateCmn = "";

  if (Object.keys(state.product).length > 0) {
    var tempArr = !("menuNavigation" in state.product[0])
      ? Array()
      : state.product[0].menuNavigation;
    if (Object.keys(tempArr).length > 1) {
      if (tempArr[0].status === "ok") {
        navigateRst = tempArr[0].result_set;
        navigateCmn = tempArr[0].common;
        menu_type =
          tempArr[0].result_set[0].menu_type == "main"
            ? "category"
            : "subcategory";

        menu_slug = tempArr[1].menu_slug;
        menu_cat_id = tempArr[1].menu_cat_id;
        selectedCatry = tempArr[1].menu_name;
      }
      if (tempArr[2].status === "ok") {
        sub_navigateRst = tempArr[2].result_set;
        sub_navigateCmn = tempArr[2].common;
        sub_selectedCatry = tempArr[2].result_set[0].subcategory_name;
        selectedsubNavigation = tempArr[2].result_set[0].pro_subcate_slug;
      }else{

      }
    }else{
      let url_path = window.location.pathname;
      let seg = url_path.substring(url_path.lastIndexOf('/') + 1);
      showAlert("Error", "Sorry!. "+seg.charAt(0).toUpperCase() + seg.slice(1)+" products not available for the selected outlet.");
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });

      setTimeout(function(){
        window.location.href = '/';
      },2000)
      return false;
    }
  }

  return {
    navigateMenu: navigateRst,
    navigateCommon: navigateCmn,
    selectedCatry: selectedCatry,
    selectedNavigation: menu_slug,
    selectedMenucatid: menu_cat_id,
    selectedSlugType: menu_type,
    sub_navigateMenu: sub_navigateRst,
    sub_selectedCatry: sub_selectedCatry,
    selectedsubNavigation: selectedsubNavigation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMenuNavigationList: (menu_category_id, menu_slug, menu_name) => {
      dispatch({
        type: GET_MENU_NAVIGATION,
        menu_category_id,
        menu_slug,
        menu_name,
      });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(MenuNavigation);
