/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import axios from "axios";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import update from "immutability-helper";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import HomeBanner from "./HomeSubCompo/HomeBanner";
import PantryProducts from "./HomeSubCompo/PantryProducts";
import PromoProducts from "./HomeSubCompo/PromoProducts";

import {
  appId,
  apiUrl,
  deliveryId,
  pickupId,
  dineinId,
  cateringId,
  reservationId,
  madbarId,
  companyname,
  timThumpUrl,
} from "../Helpers/Config";
import {
  GET_GLOBAL_SETTINGS,
  GET_NORMAL_POPUP,
  GET_CATEGORY_LIST,
  GET_MENU_NAVIGATION,
} from "../../actions";
import {
  showLoader,
  hideLoader,
  validateEmailFun,
  showCustomAlert,
  stripslashes,
  callImage,
} from "../Helpers/SettingHelper";

import newletterImg from "../../common/images/newletter-bg.jpg";

import db from "../../common/images/delivery-bike-white.svg";
import res from "../../common/images/reservation-white.svg";
import din from "../../common/images/dinein-white.svg";
import cater from "../../common/images/catering-white.svg";
import maintanceImg from "../../common/images/maintance.png";

import joinusImg from "../../common/images/join-us-img.png";
import joinusIcon from "../../common/images/join-logo.png";
import cellicon from "../../common/images/cell.png";
import appstore from "../../common/images/app-store.svg";
import gp from "../../common/images/gp-badge.svg";

import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import Parser from "html-react-parser";
const settingsJourney = {
  items: 1,
  autoplay: false,
  loop: true,
  nav: false,
};
const settingsGallery = {
  items: 3,
  autoplay: true,
  loop: false,
  nav: false,
};
class Home extends Component {
  constructor(props) {
    super(props);

    var normalpopupFlg = "initial";
    if (
      (cookie.load("triggerAvlPop") != "" &&
        cookie.load("triggerAvlPop") != undefined) ||
      cookie.load("orderPopuptrigger") === "Yes" ||
      cookie.load("loginpopupTrigger") === "Yes" ||
      cookie.load("promoPopupTrigger") === "Yes"
    ) {
      normalpopupFlg = "trigger";
    }

    this.state = {
      users: [],
      nextavail: "",
      cartTriggerFlg: "No",
      staticblacks: [],
      homeAboutBlk: "",
      homememBlk: "",
      homefeatureBlk: "",
      homepromoBlk: "",
      homeBlogBlk: "",
      homejournyBlk: "",
      homejournyimgBlk: [],
      homejournyimgpath: "",
      homegrammedBlk: "",
      homeLightContent: "",
      homeDarkContent: "",
      homecafe: "",
      homesubscriptionblk: "",
      homeblksorting: "",
      viewProductFlg: "no",
      viewProductSlug: "",
      normalpopuplist: [],
      normalpopupdata: [],
      globalsettings: [],
      member_benifits_info: "",
      normalpopup_status: "no",
      normalpopup_flg: normalpopupFlg,
      normalpopupstatus: "initial",
      promo_mail_id: "",
      displayType: "all",
      newsletterfields: {
        email: "",
        firstname: "",
        lastname: "",
        terms: "",
      },
      promomail_error: "",
      homeGallery: [],
      client_password_maintain: "",
      maintanace_mode: "",
      client_password_maintain_error: "",
      maintanace_popup_hide: false,
      whatshappenings_source: "",
      whatshappenings: [],
      changeavailability: "No",
      changeavailabilityvoucher: "No",
      updated_availability: "",
      openPopup: true,
    };
    if (this.props.match.path === "/chopchoptracking/token/:tokenID") {
      if (
        this.props.match.params.tokenID !== "" &&
        typeof this.props.match.params.tokenID !== !undefined &&
        typeof this.props.match.params.tokenID !== "undefined"
      ) {
        cookie.save("chopchoptoken", "Yes", {
          path: "/",
        });
        this.props.history.push("/");
      }
    }
    this.props.getSettings();
    this.props.getCategoryList();
    this.props.getMenuNavigation();
    this.props.getNormalPopup();
  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.staticblack !== this.state.staticblacks) {
      var homeAboutBlk = "",
        homefeatureBlk = "",
        homepromoBlk = "",
        homeBlogBlk = "",
        homejournyBlk = "",
        homejournyimgpath = "",
        homegrammedBlk = "",
        homecafe = "",
        homeblksorting = "",
        homeLightContent = "",
        homeDarkContent = "",
        homememBlk = "",
        homesubscriptionblk = "";
      var homejournyimgBlk = [];
      var homeGallery = [];
      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data) => {
          if (data.staticblocks_slug === "home-about-us") {
            homeAboutBlk = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-feature-product") {
            homefeatureBlk = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-promotion") {
            homepromoBlk = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-banner") {
            homememBlk = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-blog-post") {
            homeBlogBlk = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-our-story") {
            homejournyBlk = data.staticblocks_description;
            homejournyimgBlk = data.gallery_images;
            homejournyimgpath = data.gallery_image_path;
            return "";
          }
          if (data.staticblocks_slug === "home-journey-grammed") {
            homegrammedBlk = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-order-now") {
            homecafe = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-block-sorting") {
            homeblksorting = data.staticblocks_description;
            homeblksorting = homeblksorting.replace("<p>", "");
            homeblksorting = homeblksorting.replace("</p>", "");
            return "";
          }
          if (data.staticblocks_slug === "home-subscription") {
            homesubscriptionblk = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-gallery") {
            homeGallery = data;
            return "";
          }
          if (data.staticblocks_slug === "home-dark-content") {
            homeDarkContent = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-light-content") {
            homeLightContent = data.staticblocks_description;
            return "";
          }
        });
      }
      homeAboutBlk =
        homeAboutBlk !== "" && homeAboutBlk !== null
          ? Parser(homeAboutBlk)
          : homeAboutBlk;
      homefeatureBlk =
        homefeatureBlk !== "" && homefeatureBlk !== null
          ? Parser(homefeatureBlk)
          : homefeatureBlk;
      homepromoBlk =
        homepromoBlk !== "" && homepromoBlk !== null
          ? Parser(homepromoBlk)
          : homepromoBlk;
      homeBlogBlk =
        homeBlogBlk !== "" && homeBlogBlk !== null
          ? Parser(homeBlogBlk)
          : homeBlogBlk;
      homejournyBlk =
        homejournyBlk !== "" && homejournyBlk !== null
          ? Parser(homejournyBlk)
          : homejournyBlk;
      homememBlk =
        homememBlk !== "" && homememBlk !== null
          ? Parser(homememBlk)
          : homememBlk;
      homegrammedBlk =
        homegrammedBlk !== "" && homegrammedBlk !== null
          ? Parser(homegrammedBlk)
          : homegrammedBlk;
      homecafe =
        homecafe !== "" && homecafe !== null ? Parser(homecafe) : homecafe;
      homesubscriptionblk =
        homesubscriptionblk !== "" && homesubscriptionblk !== null
          ? Parser(homesubscriptionblk)
          : homesubscriptionblk;
      homeDarkContent =
        homeDarkContent !== "" && homeDarkContent !== null
          ? Parser(homeDarkContent)
          : homeDarkContent;
      homeLightContent =
        homeLightContent !== "" && homeLightContent !== null
          ? Parser(homeLightContent)
          : homeLightContent;

      this.setState({
        staticblacks: PropsData.staticblack,
        homeAboutBlk: homeAboutBlk,
        homefeatureBlk: homefeatureBlk,
        homepromoBlk: homepromoBlk,
        homeBlogBlk: homeBlogBlk,
        homejournyBlk: homejournyBlk,
        homejournyimgBlk: homejournyimgBlk,
        homejournyimgpath: homejournyimgpath,
        homegrammedBlk: homegrammedBlk,
        homecafe: homecafe,
        homeblksorting: homeblksorting,
        homesubscriptionblk: homesubscriptionblk,
        homeGallery: homeGallery,
        homeDarkContent: homeDarkContent,
        homeLightContent: homeLightContent,
        homememBlk: homememBlk,
      });
    }

    if (
      PropsData.normalpopuplist !== this.state.normalpopuplist &&
      this.state.normalpopup_flg === "initial"
    ) {
      var normalpopupMain = PropsData.normalpopuplist,
        normalpopupdata = [],
        normalpopupStatus = "no";
      if (normalpopupMain !== "") {
        if (Object.keys(normalpopupMain).length > 0) {
          var normalpopupIds = cookie.load("normalpopupIds");
          var normalpopupIdArr =
            normalpopupIds != "" && normalpopupIds != undefined
              ? normalpopupIds.split("~~")
              : Array();
          if (
            $.inArray(normalpopupMain[0].normalpopup_id, normalpopupIdArr) ===
            -1
          ) {
            normalpopupdata = normalpopupMain[0];
            normalpopupStatus = "yes";
          }
          this.setState({
            normalpopuplist: normalpopupMain,
            normalpopupdata: normalpopupdata,
            normalpopup_status: normalpopupStatus,
            normalpopup_flg: "trigger",
          });
        }
      }
    }

    if (PropsData.normalpopupstatus !== this.state.normalpopupstatus) {
      this.setState({ normalpopupstatus: PropsData.normalpopupstatus });
    }

    if (PropsData.globalsettings !== this.props.globalsettings) {
      var settingsArrList = PropsData.globalsettings[0];
      if (Object.keys(settingsArrList).length > 0) {
        if (settingsArrList.status === "ok") {
          let checkMaintananceMode =
            settingsArrList.result_set.client_maintenance_mode;
          this.setState({ maintanace_mode: checkMaintananceMode });
          if (checkMaintananceMode === "1") {
            if (
              cookie.load("loginpopupTrigger") !== "Yes" &&
              cookie.load("loginpopupTrigger") !== "fromcheckout"
            ) {
              /* $.magnificPopup.close(); */
            }
            cookie.save("site_maintenance_popup_open", "1", { path: "/" });
          }
          cookie.save(
            "fb_pixel_id",
            settingsArrList.client_fb_pixel_id !== null
              ? settingsArrList.result_set.client_fb_pixel_id
              : ""
          );
          cookie.save(
            "ga_id",
            settingsArrList.client_google_ga_id !== null
              ? settingsArrList.result_set.client_google_ga_id
              : ""
          );
          cookie.save(
            "gtm_id",
            settingsArrList.client_google_gtm_id !== null
              ? settingsArrList.result_set.client_google_gtm_id
              : ""
          );
          cookie.save(
            "home_page_add_to_cart_availability",
            settingsArrList.home_page_add_to_cart_availability !== null
              ? settingsArrList.result_set
                  .client_skip_home_page_add_to_cart_availability
              : ""
          );
        }
      }
    }
  }

  componentDidMount() {
    if (cookie.load("UserId") > 0) {
      setTimeout(function() {
        $(document)
          .find(".open-popup-link")
          .hide();
      }, 2000);
    }
    $(".lightbox-cats").magnificPopup({
      type: "image",
      gallery: { enabled: true },
    });
    if (
      cookie.load("triggerAvlPop") != "" &&
      cookie.load("triggerAvlPop") != undefined
    ) {
      var availabilityId = cookie.load("triggerAvlPop");
      cookie.remove("triggerAvlPop", { path: "/" });
      this.chooseAvailability(availabilityId);
    }

    if (cookie.load("orderPopuptrigger") === "Yes") {
      cookie.remove("orderPopuptrigger", { path: "/" });
      setTimeout(function() {
        $.magnificPopup.open({
          items: {
            src: "#order-popup",
          },
          type: "inline",
        });
      }, 3000);
    }

    if (cookie.load("loginpopupTrigger") === "Yes") {
      cookie.save("loginpopupTrigger", "fromcheckout", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }

    if (cookie.load("rfcodeAction") === "Yes") {
      cookie.remove("rfcodeAction");
      $.magnificPopup.open({
        items: {
          src: "#signup-popup",
        },
        type: "inline",
      });
    }

    axios.get(apiUrl + "cms/whats_happening?app_id=" + appId).then((res) => {
      if (res.data.status === "ok") {
        let whatshappenings_source = res.data.common
          ? res.data.common.image_source
          : "";
        let whatshappenings = res.data.result_set;

        this.setState({
          whatshappenings_source: whatshappenings_source,
          whatshappenings: whatshappenings,
        });
      } else if (res.data.status === "error") {
      }

      return false;
    });

    axios
      .get(
        apiUrl +
          "products/category?app_id=" +
          appId +
          "&category_id=AE27DB45-BD57-4401-99D7-108742AF09A6&status=A"
      )
      .then((res) => {
        if (res.data.status === "ok") {
          $(".voucherlink").show();
        } else if (res.data.status === "error") {
          $(".voucherlink").hide();
        }
      });

    $(document).ready(function() {
      $(document).on("click", ".home-about-us-dots", function() {
        $(".home-about-us-dots").hide();
        $(".home-about-us-more").show();
      });
      $(document).on("click", ".view_member_popup", function() {
        window.$.magnificPopup.open({
          items: {
            src: "#member-details-popup",
          },
          type: "inline",
        });
      });
      $(document).on("click", ".sign-up-link", function() {
        window.$.magnificPopup.open({
          items: {
            src: "#signup-popup",
          },
          type: "inline",
        });
      });

      $(document).on("click", ".home-about-us-less", function() {
        $(".home-about-us-more").hide();
        $(".home-about-us-dots").show();
      });

      $(document).on("click", ".home-our-journey-dots", function() {
        $(".home-our-journey-dots").hide();
        $(".home-our-journey-more").show();
      });

      $(document).on("click", ".home-our-journey-less", function() {
        $(".home-our-journey-more").hide();
        $(".home-our-journey-dots").show();
      });
      setTimeout(function() {
        $(".banner.loader-sub-div").remove();
      }, 500);
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.staticblack !== this.props.staticblack) {
      let member_benifits_info = "";

      if (Object.keys(this.props.staticblack).length > 0) {
        this.props.staticblack.map((data, index) => {
          if (data.staticblocks_slug === "member-benefits") {
            member_benifits_info = data.staticblocks_description;
          }
        });
      }

      member_benifits_info =
        member_benifits_info !== ""
          ? Parser(member_benifits_info)
          : member_benifits_info;
      this.setState({ member_benifits_info: member_benifits_info });
    }
    // $(document).ready(function() {
    //   $('.home-about-us-more').hide();
    //   $('.home-our-journey-more').hide();
    // })
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
    if (field === "view_pro_data" && value !== "") {
      this.setState(
        { viewProductFlg: "yes", viewProductSlug: value },
        function() {
          this.openProDetailPopup();
        }.bind(this)
      );
    }
    if (field === "view_pro_upate" && value !== "") {
      this.setState({ viewProductFlg: value });
    }
    if (field === "firstcategory" && value !== "") {
      this.setState({ firstcategory: value });
    }
    if (field === "changeavailability" && value !== "") {
      this.setState({ changeavailability: value });
    }
    if (field === "changeavailabilityvoucher" && value !== "") {
      this.setState({ changeavailabilityvoucher: value });
    }
  };

  openProDetailPopup() {
    showLoader("comboPro-" + this.state.viewProductSlug, "Idtext");
    $("#ProductDetailMdl").modal({ backdrop: "static", keyboard: false });
  }

  triggerNormalPopup(trgType) {
    if (this.state.openPopup === true) {
      var otherPageActTrigger = "no";
      if (
        (cookie.load("triggerAvlPop") != "" &&
          cookie.load("triggerAvlPop") != undefined) ||
        cookie.load("orderPopuptrigger") === "Yes" ||
        cookie.load("loginpopupTrigger") === "Yes"
      ) {
        otherPageActTrigger = "yes";
      }

      if (
        trgType === "loading" &&
        otherPageActTrigger === "no" &&
        this.state.normalpopup_status === "yes" &&
        Object.keys(this.state.normalpopupdata).length > 0 &&
        cookie.load("promoPopupTrigger") !== "Yes"
      ) {
        console.log(cookie.load("mailpopopuptrg"), "dfsfsfsdfsf");
        cookie.save("mailpopopuptrg", "yes", { path: "/" });
        var normalpopupIds = cookie.load("normalpopupIds");
        var normalpopupIdsNew =
          normalpopupIds != "" && normalpopupIds != undefined
            ? normalpopupIds + "~~" + this.state.normalpopupdata.normalpopup_id
            : this.state.normalpopupdata.normalpopup_id;
        var normalpopupIdArr = [];
        normalpopupIdArr["normalpopupids"] = normalpopupIdsNew;
        cookie.save("normalpopupIds", normalpopupIdsNew, { path: "/" });
        var $_this_rec = this;
        console.log(this.state.openPopup, "aaaaaaaaaaaaaaaaaaaaa");
        $_this_rec.setState({ openPopup: false });
        $.magnificPopup.open({
          items: {
            src: "#normal-popup",
          },
          type: "inline",
          midClick: true,
          closeOnBgClick: false,
          callbacks: {
            close: function() {
              $_this_rec.normalPopupUpdate();
            },
          },
        });
      }

      if (
        (cookie.load("promoPopupTrigger") === "Yes" ||
          (otherPageActTrigger === "no" &&
            this.state.normalpopup_status === "no" &&
            Object.keys(this.state.normalpopupdata).length === 0)) &&
        cookie.load("mailpopopuptrg") !== "yes"
      ) {
        cookie.save("mailpopopuptrg", "yes", { path: "/" });
        cookie.remove("promoPopupTrigger", { path: "/" });
        var globalsettings = this.props.globalsettings;
        if (Object.keys(globalsettings).length > 0) {
          if (globalsettings[0].result_set.client_promocode_options === "1") {
            $.magnificPopup.open({
              items: {
                src: "#promo-check-popup",
              },
              type: "inline",
              midClick: true,
              closeOnBgClick: false,
            });
          }
        }
      }
    }
  }

  normalPopupUpdate() {
    if (cookie.load("mailpopopuptrg") !== "yes") {
      //  this.props.history.push("/refpage/promopopup");
    }
  }

  handleEmailChange(event) {
    this.setState({ promo_mail_id: event.target.value, promomail_error: "" });
  }

  sendPromoMailFun() {
    var promoEmail = this.state.promo_mail_id;

    var mailErrorTxt = "";
    if (promoEmail === "") {
      mailErrorTxt = "Email Address is required.";
    } else if (!validateEmailFun(promoEmail)) {
      mailErrorTxt = "Invalide Email Address";
    }

    if (mailErrorTxt !== "") {
      this.setState({ promomail_error: mailErrorTxt });
      return false;
    } else {
      showLoader("promomailpopup-cls", "class");

      var qs = require("qs");
      var postObject = {
        app_id: appId,
        email_address: promoEmail,
      };

      axios
        .post(apiUrl + "promotion/user_promotion", qs.stringify(postObject))
        .then((response) => {
          hideLoader("promomailpopup-cls", "class");
          $.magnificPopup.close();
          if (response.data.status === "ok") {
            showCustomAlert(
              "success",
              "You are now a Member of " +
                companyname +
                ". Please check your email for more information."
            );
          } else {
            var errMsgtxt =
              response.data.message !== ""
                ? response.data.message
                : "Sorry! You didn`t have promo code.";
            showCustomAlert("error", errMsgtxt);
          }
          return false;
        });
    }

    return false;
  }

  setdisplayType(displayType) {
    this.setState({ displayType: displayType });
  }

  checkActiveDivHd(avlType) {
    var clsTxt = "";
    var availability = cookie.load("defaultAvilablityId");
    if (availability == avlType) {
      clsTxt += "active";
    }
    return clsTxt;
  }

  openOrders(type, event) {
    event.preventDefault();
    $.magnificPopup.open({
      items: {
        src: "#delevery-postcode-popup",
      },
      type: "inline",
    });
  }

  loadHomeSection() {
    if (this.state.homeblksorting !== "") {
      var home_blk_sorting = this.state.homeblksorting.split("|");
      return home_blk_sorting.map((item, index) => {
        if (item.trim() === "highlight-products") {
          return (
            <section className="highlight-products" key={index}>
              <PantryProducts
                {...this.props}
                sateValChange={this.sateValChange}
                homefeatureBlk={this.state.homefeatureBlk}
              />
            </section>
          );
        } else if (item.trim() === "promo-product") {
          return (
            <section className="promo-product" key={index}>
              <PromoProducts
                {...this.props}
                sateValChange={this.sateValChange}
                homepromoBlk={this.state.homepromoBlk}
              />
            </section>
          );
        } else if (item.trim() === "home-order-now") {
          return <div key={index}>{this.state.homecafe}</div>;
        } else if (item.trim() === "home-promotion") {
          return <div key={index}>{this.listwhatshappenings()}</div>;
        } else if (item.trim() === "home-our-story") {
          if (this.state.homejournyBlk !== "") {
            return (
              <>
                <section className="home-our-journey" key={index}>
                  <div className="container">
                    <div className="hoj-inner">
                      {this.state.homejournyBlk}
                      {this.state.homejournyimgBlk.length > 0 && (
                        <div className="hoj-rhs">
                          <OwlCarousel options={settingsJourney}>
                            {this.state.homejournyimgBlk.map((item, index) => {
                              if (item !== "") {
                                return (
                                  <div key={index}>
                                    <img
                                      src={this.state.homejournyimgpath + item}
                                      alt={companyname}
                                    />
                                  </div>
                                );
                              }
                            })}
                          </OwlCarousel>
                        </div>
                      )}
                    </div>
                  </div>
                </section>
                {this.state.homememBlk}
              </>
            );
          }
        }
      });
    }
  }

  listwhatshappenings() {
    let Promotions = {
      loop: false,
      items: 4,
      margin: 20,
      nav: false,
      dots: true,
      responsive: {
        0: {
          items: 2,
          margin: 10,
        },
        600: {
          items: 3,
        },
        1000: {
          items: 3,
        },
        1200: {
          items: 4,
        },
      },
    };

    let whatshappenings = this.state.whatshappenings;
    let whatshappenings_source = this.state.whatshappenings_source;

    if (Object.keys(whatshappenings).length > 0) {
      const mainMenu = whatshappenings.map((loaddata, index) => {
        return (
          <div className="our-promotions-section" key={"wh" + (index + 1)}>
            <div className="container">
              <h2>
                {loaddata.wh_type === "promotions" && "Promotions"}
                {loaddata.wh_type === "happenings" && "Happenings"}
              </h2>

              <OwlCarousel options={Promotions}>
                {loaddata.gallery_image.map((loaddatagall, gallindex) => {
                  if (loaddatagall.outlet.length > 1) {
                    return (
                      <div
                        key={"whgall" + index + "" + (gallindex + 1)}
                        /*  onClick={() =>
                          this.setState({
                            photoIndex:
                              whatshappenings_source +
                              "/" +
                              loaddatagall.gallery_image,
                            isOpen: true,
                          })
                        } */
                      >
                        <a
                          href={
                            whatshappenings_source + loaddatagall.gallery_image
                          }
                          className={
                            loaddata.wh_type === "promotions"
                              ? "lightbox-cats"
                              : "lightbox-happen"
                          }
                        >
                          {/*callImage(
                            whatshappenings_source,
                            loaddatagall.gallery_image,
                            315,
                            447,
                            timThumpUrl
                          )*/}
                          <img
                            src={
                              whatshappenings_source +
                              loaddatagall.gallery_image
                            }
                          />
                        </a>
                      </div>
                    );
                  }
                })}
              </OwlCarousel>
              <div className="home-bottom-link">
                <Link className="button" to={"/promotion-events"}>
                  See More
                </Link>
              </div>
            </div>
          </div>
        );
      });
      $(".lightbox-cats").magnificPopup({
        type: "image",
        gallery: { enabled: true },
      });
      $(".lightbox-happen").magnificPopup({
        type: "image",
        gallery: { enabled: true },
      });

      return mainMenu;
    } else {
      return null;
    }
  }

  fieldChange = (field, value) => {
    this.setState(
      update(this.state, { newsletterfields: { [field]: { $set: value } } })
    );
  };

  submitNewsletter = () => {
    const formPayload = this.state.newsletterfields;
    var qs = require("qs");
    var postObject = {
      app_id: appId,
      firstname: formPayload.firstname,
      lastname: formPayload.lastname,
      email: formPayload.email,
      terms: formPayload.terms,
    };
    showLoader("subscribe_submit", "class");
    axios
      .post(apiUrl + "newsletter/subscription", qs.stringify(postObject))
      .then((response) => {
        hideLoader("subscribe_submit", "class");
        $.magnificPopup.close();
        if (response.data.status === "ok") {
          showCustomAlert("success", response.data.message);
          if (
            response.data.message !==
            "Your Email was already subscribed to the Newsletter."
          ) {
            setTimeout(function() {
              window.location.reload();
            }, 2000);
          }
        } else {
          var errMsgtxt =
            response.data.message !== ""
              ? response.data.message
              : "Something went wrong! Try after sometime.";
          showCustomAlert("error", errMsgtxt);
        }
        return false;
      });
  };

  availabilityList() {
    var settingsArr = this.props.globalsettings;
    if (Object.keys(settingsArr).length > 0) {
      var settingsRes = settingsArr[0].result_set.availability;
      var availabilityList = [];
      if (settingsRes.length > 0) {
        settingsRes.map((item) => {
          availabilityList.push(item.availability_id);
        });
      }
      return (
        <ul>
          {settingsRes.map((item, index) => {
            var imageName = "";
            if (item.availability_id === madbarId) {
              imageName = db;
            } else if (item.availability_id === deliveryId) {
              imageName = db;
            } else if (item.availability_id === pickupId) {
              imageName = din;
            } else if (item.availability_id === dineinId) {
              imageName = din;
            } else if (item.availability_id === reservationId) {
              imageName = res;
            } else if (item.availability_id === cateringId) {
              imageName = cater;
            }
            if (item.availability_id !== reservationId) {
              return (
                <li className="hci-three" key={index}>
                  <a
                    href={void 0}
                    onClick={this.setAvailabilityFun.bind(
                      this,
                      item.availability_id
                    )}
                    className={this.checkActiveDivHd(item.availability_id)}
                  >
                    <div>
                      <img className="hci-normal" src={imageName} />{" "}
                    </div>
                    {item.display_name !== "" && item.display_name !== null
                      ? item.display_name
                      : item.availability_name}
                  </a>
                </li>
              );
            }
          })}
          <li className="hci-three voucherlink hidden">
            <a
              href={void 0}
              onClick={this.setAvailabilityFunvoucher.bind(this)}
            >
              <div>
                <img className="hci-normal" src={cater} />{" "}
              </div>
              Vouchers
            </a>
          </li>
        </ul>
      );
    }
  }

  setAvailabilityFun(availability, event) {
    event.preventDefault();
    /* if (cookie.load("defaultAvilablityId") !== availability) { */
    var changeavailabilityvoucher = "Yes";
    if (availability === cateringId) {
      changeavailabilityvoucher = "No";
    }
    this.setState({
      changeavailability: "Yes",
      updated_availability: availability,
      changeavailabilityvoucher: "No",
    });
    /* } else {
    } */
  }

  setAvailabilityFunvoucher(event) {
    event.preventDefault();
    this.setState({
      changeavailability: "Yes",
      updated_availability: "",
      changeavailabilityvoucher: "Yes",
    });
    var orderOutletId =
      cookie.load("orderOutletId") != "" &&
      cookie.load("orderOutletId") != undefined
        ? cookie.load("orderOutletId")
        : "";
    var avilablityId =
      cookie.load("defaultAvilablityId") != "" &&
      cookie.load("defaultAvilablityId") != undefined
        ? cookie.load("defaultAvilablityId")
        : "";

    if (orderOutletId === "" || avilablityId === "") {
      $.magnificPopup.open({
        items: {
          src: "#order-popup",
        },
        type: "inline",
      });
    } else {
      this.props.history.push("/products/category/voucher");
    }
  }

  serviceMaintanance() {
    if (this.state.client_password_maintain !== "") {
      this.setState({ client_password_maintain_error: "" });

      var postObject = {
        app_id: appId,
        client_password: this.state.client_password_maintain,
      };

      var qs = require("qs");

      axios
        .post(
          apiUrl + "settings/checking_maintenance",
          qs.stringify(postObject)
        )
        .then((response) => {
          if (response.data.status === "ok") {
            $(".maintance-popup").modal("hide");
            $(".maintance-popup").addClass("mfp-hide");
            this.setState({ maintanace_popup_hide: true });
            cookie.save("site_maintenance_popup_open", "0", { path: "/" });
            cookie.save("site_maintenance_verify", "1", { path: "/" });
          } else {
            $(".maintance-popup").modal("show");
            this.setState({ client_password_maintain_error: "2" });
            cookie.save("site_maintenance_popup_open", "1", { path: "/" });
            cookie.save("site_maintenance_verify", "0", { path: "/" });
          }
        });
    } else {
      cookie.save("site_maintenance_popup_open", "1", { path: "/" });
      cookie.save("site_maintenance_verify", "0", { path: "/" });
      this.setState({ client_password_maintain_error: "1" });
    }
  }

  handleChangePassword(event) {
    if (event.target.name === "client_password_maintain") {
      this.setState({ client_password_maintain: event.target.value });
    }
  }

  render() {
    let settingsArr = this.props.globalsettings;
    var showPromoPopup = "",
      showNormalPopup = "",
      client_theme = 0;
    if (Object.keys(settingsArr).length > 0) {
      if (Object.keys(settingsArr[0].result_set).length > 0) {
        var client_theme = settingsArr[0].result_set.client_theme;
        if (settingsArr[0].result_set.client_promocode_enable === "1") {
          showNormalPopup =
            settingsArr[0].result_set.client_promo_code_normal_popup_enable ===
            "1"
              ? "yes"
              : "";
          showPromoPopup =
            settingsArr[0].result_set.client_promocode_options === "1"
              ? "yes"
              : "";
        }
      }
    }
    return (
      <div className="home-main-div">
        {/* Header section */}
        <Header
          homePageState={this.state}
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
        />

        {/* Home banner section */}
        <section className="white-home">
          <HomeBanner />
        </section>
        <section className="home-content">
          <div className="container">
            <div className="home-content-inner">{this.availabilityList()}</div>
          </div>
        </section>
        {this.loadHomeSection()}
        {Object.keys(this.state.homeGallery).length > 0 && (
          <section className="home-insta-feed dymic-insta-feed">
            <section className="instapro-section">
              <div className="instapro-section-inner">
                <div className="container">
                  <div className="home-insta-inner">
                    <div className="home-insta-lhs">
                      <OwlCarousel options={settingsGallery}>
                        {this.state.homeGallery.gallery_images.map(
                          (loaddatagall, gallindex) => {
                            return (
                              <div
                                key={gallindex}
                                className="home-instafeed-image"
                              >
                                <img
                                  src={
                                    this.state.homeGallery.gallery_image_path +
                                    loaddatagall
                                  }
                                  alt="Gallery"
                                />
                              </div>
                            );
                          }
                        )}
                      </OwlCarousel>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
        )}
        {/* Footer section */}
        <Footer />
        {/*Normal popup Start*/}
        <div
          id="normal-popup"
          className="white-popup mfp-hide popup_sec normal-popup"
        >
          <div className="normal_popup_alert">
            {this.state.normalpopup_status === "yes" &&
              Object.keys(this.state.normalpopupdata).length > 0 && (
                <div>
                  <div className="full-login-new-header">
                    <h3>
                      {" "}
                      {stripslashes(
                        this.state.normalpopupdata.normalpopup_title
                      )}
                    </h3>
                  </div>
                  <div className="normal_popup_cont">
                    {Parser(this.state.normalpopupdata.normalpopup_description)}
                  </div>
                </div>
              )}
          </div>
        </div>
        {/*Normal popup - End*/}

        <div
          id="member-details-popup"
          className="white-popup mfp-hide popup_sec"
        >
          <div className="pouup_in">
            <div id="form-msg"></div>
            <h3 className="title1 text-center">Tier Membership</h3>
            <div className="drop_div_nfc">
              <div className="list_nfcn">{this.state.member_benifits_info}</div>
            </div>
          </div>
        </div>

        {/*  Serive Maintanance Popup - start */}
        {/* this.state.maintanace_mode === "1" &&
          cookie.load("site_maintenance_popup_open") === "1" &&
          (site_maintenance_verify === "" ||
            site_maintenance_verify === "0") && (
            <>
              { this.state.maintanace_popup_hide === false && (
                <div class="mfp-bg mfp-ready"></div>
              ) }
              <div className="maintance-popup-main">
                <div
                  className={
                    this.state.maintanace_mode === "1"
                      ? "maintance-popup"
                      : "maintance-popup mfp-hide"
                  }
                  data-target="#myModal"
                  data-toggle="modal"
                  data-backdrop="static"
                  data-keyboard="false"
                >
                  <div className="maintance-popup-container">
                    <div className="mtnce-pup-imager">
                      <img className="mtnce-pup-img" src={maintanceImg} />
                    </div>
                    <div className="maintance-popup-contant">
                      <h3>site is under maintainance</h3>
                      <p>
                        {" "}
                        Please enter admin password to browse through website
                      </p>

                      <div className="maintance-popup-input">
                        <input
                          type="password"
                          placeholder="Enter Admin Password"
                          name="client_password_maintain"
                          value={this.state.client_password_maintain}
                          onChange={this.handleChangePassword.bind(this)}
                        />
                        <a
                          onClick={this.serviceMaintanance.bind(this)}
                          className="button disbl_href_action"
                          title="Continue"
                        >
                          Continue
                        </a>
                      </div>
                      {this.state.client_password_maintain_error === "1" && (
                        <span className="error">This field is required. </span>
                      )}
                      {this.state.client_password_maintain_error === "2" && (
                        <span className="error">Password is incorrect. </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) */}
        {/* Serive Maintanance - end */}

        {/*Promo Check Email popup Start*/}
        <div
          id="promo-check-popup"
          className="white-popup mfp-hide popup_sec promo-check-popup"
        >
          <div className="promopopup-maindiv">
            <div className="promopopup-lhs">
              <div className="frm-action-div">
                <div className="frm-top-title">
                  <h3>Join our email list</h3>
                  <p>Enter your Email address to receive your promocode</p>
                </div>

                <div className="frm-inputbtn-div">
                  <div className="form-group">
                    <div className="focus-out">
                      <label>Email Address</label>
                      <input
                        type="email"
                        className="form-control input-focus"
                        value={this.state.promo_mail_id}
                        onChange={this.handleEmailChange.bind(this)}
                      />
                      {this.state.promomail_error !== "" && (
                        <div id="promomail-error">
                          {this.state.promomail_error}
                        </div>
                      )}
                    </div>
                  </div>

                  <button
                    type="button"
                    onClick={this.sendPromoMailFun.bind(this)}
                    className="button promomailpopup-cls"
                  >
                    Subscribe
                  </button>
                </div>

                <div className="frm-bottom-text">
                  <p>
                    SIGN UP FOR EXCLUSIVE UPDATES, NEW PRODUCTS, AND
                    INSIDER-ONLY DISCOUNTS
                  </p>
                </div>
              </div>
            </div>

            <div className="promopopup-rhs">
              <img src={newletterImg} />
            </div>
          </div>
        </div>
        {/*Promo Check Email popup - End*/}

        {this.state.normalpopupstatus !== "initial" &&
          (showNormalPopup === "yes" || showPromoPopup === "yes") &&
          this.triggerNormalPopup("loading")}
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }

  var normalPopupStatus = "initial";
  var normalPopupArr = Array();
  if (Object.keys(state.normalpopuplist).length > 0) {
    if (state.normalpopuplist[0].status === "ok") {
      normalPopupArr = state.normalpopuplist[0].result_set;
      normalPopupStatus = "ok";
    } else {
      normalPopupStatus = "error";
    }
  }

  var tempArr = Array();
  var navigateRst = Array();
  var navigateCmn = Array();
  if (Object.keys(state.product).length > 0) {
    var tempArr = !("menuNavigation" in state.product[0])
      ? Array()
      : state.product[0].menuNavigation;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        navigateRst = tempArr[0].result_set;
        navigateCmn = tempArr[0].common;
      }
    }
  }

  return {
    globalsettings: state.settings,
    categorylist: state.categorylist,
    navigateMenu: navigateRst,
    navigateCommon: navigateCmn,
    staticblack: blacksArr,
    normalpopuplist: normalPopupArr,
    normalpopupstatus: normalPopupStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getCategoryList: () => {
      dispatch({ type: GET_CATEGORY_LIST });
    },
    getNormalPopup: () => {
      dispatch({ type: GET_NORMAL_POPUP });
    },
    getMenuNavigation: () => {
      dispatch({ type: GET_MENU_NAVIGATION });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Home);
