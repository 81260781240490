import {SET_SOCIALSETTINGS } from '../actions';

const socialsettings = (state = [], action) => {

  switch (action.type) {
   case SET_SOCIALSETTINGS:
      return [...action.value];
    default: return state;
  }
}

export default socialsettings;
