/* eslint-disable */
import React, { Component } from "react";
import {
  timeToConv12,
  showPriceValue,
  stripslashes,
  jsUcfirstFun,
  smoothScroll,
} from "../../Helpers/SettingHelper";
import cookie from "react-cookies";

export default class Cartpopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cart_flag: "",
      popupcart_data: [],
      popupcartDetailsMain: [],
      popupcartItems: [],
      cart_brktime_list: [],
    };
  }

  componentWillReceiveProps(productProps) {
    if (
      productProps.proState.cart_flag == "yes" &&
      Object.keys(productProps.proState.cartData).length > 0
    ) {
      var cartDetailsMain = productProps.proState.cartData.cart_details;

      var cartItems = productProps.proState.cartData.cart_items;

      this.setState(
        {
          cart_flag: productProps.proState.cart_flag,
          popupcart_data: productProps.proState.cartData,
          popupcartDetailsMain: cartDetailsMain,
          popupcartItems: cartItems,
        },
        function() {
          this.makeBrkTimeList();
        }.bind(this)
      );
    }
  }

  makeBrkTimeList() {
    var cartDetailsSate = this.state.popupcartDetailsMain;
    var cartItems = this.state.popupcartItems;
    var cartBrkTmList = [];
    if (
      Object.keys(cartDetailsSate).length > 0 &&
      Object.keys(cartItems).length > 0
    ) {
      var breaktimeEnable = cartDetailsSate.cart_breaktime_enable;
      var breaktimeCount = cartDetailsSate.cart_breaktime_count;
      if (breaktimeEnable === "Yes" && parseInt(breaktimeCount) > 0) {
        var tempKeyArr = Array();
        var tempKey = 0;
        for (var key in cartItems) {
          var chkKey = cartItems[key].cart_item_breaktime_indexflag;
          if (!(chkKey in tempKeyArr)) {
            tempKeyArr[chkKey] = tempKey;
            var tempItemArr = Array();
            var tempItemLst = [];
            var intVl = tempKey + 1;
            var time_lable = "Break " + intVl;
            tempItemArr["cart_detaile"] = {
              break_time_lable: time_lable,
              break_time_index: cartItems[key].cart_item_breaktime_indexflag,
              break_time_start: cartItems[key].cart_item_breaktime_started,
              break_time_end: cartItems[key].cart_item_breaktime_ended,
            };
            tempItemLst[0] = cartItems[key];
            tempItemArr["cart_items"] = tempItemLst;
            cartBrkTmList[tempKey] = tempItemArr;
            tempKey++;
          } else {
            var extKey = tempKeyArr[chkKey];
            cartBrkTmList[extKey]["cart_items"].push(cartItems[key]);
          }
        }
      }
    }

    this.setState({ cart_brktime_list: cartBrkTmList });

    setTimeout(function() {
      if ($(".mCustomScrollbar").length > 0) {
        $(".mCustomScrollbar").mCustomScrollbar();
      }
    }, 800);
  }

  getCartDetList() {
    var cartDetailsSate = this.state.popupcartDetailsMain;
    var cartBrktimeLst = this.state.cart_brktime_list;
    if (Object.keys(cartDetailsSate).length > 0) {
      var breaktimeEnable = cartDetailsSate.cart_breaktime_enable;
      if (breaktimeEnable === "Yes" && Object.keys(cartBrktimeLst).length > 0) {
        return this.cartBrkTimeList();
      } else {
        return this.cartListOnly();
      }
    }
  }

  handlerActTab(indx) {
    var ariaAxp = $("#headingTb" + indx)
      .find("a")
      .attr("aria-expanded");
    if (ariaAxp === "true") {
      $("#headingTb" + indx).addClass("act");
    } else {
      $("#headingTb" + indx).removeClass("act");
    }
    $(".panel-heading")
      .not($("#headingTb" + indx))
      .removeClass("act");
  }

  cartListOnly() {
    var cartItems = this.state.popupcartItems;
    return (
      <div className="panel panel-default">
        <div
          className="panel-heading act"
          role="tab"
          id="headingTb0"
          onClick={this.handlerActTab.bind(this, 0)}
        >
          <h4 className="panel-title">
            <a
              role="button"
              data-toggle="collapse"
              data-parent="#accordion"
              aria-expanded="true"
              href="#collapse0"
              className=""
            >
              Cart Details
            </a>
          </h4>
        </div>
        <div
          id="collapse0"
          className="panel-collapse collapse mCustomScrollbar in"
          aria-expanded="true"
          href="#collapse0"
        >
          <div className="panel-body">{this.cartItemlist(cartItems)}</div>
        </div>
      </div>
    );
  }

  cartBrkTimeList() {
    var cartBrktimeLst = this.state.cart_brktime_list;

    const cartBrktmhtml = cartBrktimeLst.map((cartBrk, indx) => (
      <div className="panel panel-default" key={indx}>
        <div
          className={indx === 0 ? "panel-heading act" : "panel-heading"}
          role="tab"
          id={"headingTb" + indx}
          onClick={this.handlerActTab.bind(this, indx)}
        >
          <h4 className="panel-title">
            <a
              role="button"
              data-toggle="collapse"
              data-parent="#accordion"
              aria-expanded={indx === 0 ? "true" : "false"}
              href={"#collapse" + indx}
              className={indx === 0 ? "" : "collapsed"}
            >
              {cartBrk["cart_detaile"].break_time_lable} :{" "}
              <span className="hr">
                {timeToConv12(cartBrk["cart_detaile"].break_time_start)}
              </span>
            </a>
          </h4>
        </div>
        <div
          id={"collapse" + indx}
          className={
            indx === 0
              ? "panel-collapse collapse mCustomScrollbar in"
              : "panel-collapse collapse mCustomScrollbar"
          }
          aria-expanded={indx === 0 ? "true" : "false"}
          href={"#collapse" + indx}
        >
          <div className="panel-body">
            <div className="checkout_time">
              {this.cartItemlist(cartBrk["cart_items"])}
            </div>
          </div>
        </div>
      </div>
    ));
    return cartBrktmhtml;
  }

  cartItemlist(itemLst) {
    if (Object.keys(itemLst).length > 0) {
      const cartlsthtml = itemLst.map((cartLst, indx1) => (
        <div key={indx1} className="listcart-induvl-row">
          {/* package div start */}
          <div className="package">
            <div className="row-replace package_cnt">
              <div className="col-sm-cls">
                <span className="title">
                  {stripslashes(cartLst.cart_item_product_name)}
                </span>
              </div>

              <div className="col-sm-cls">
                <div className="qty_bx_textonly">
                  {" "}
                  {cartLst.cart_item_qty} pax{" "}
                </div>
                <h4 className="hr-flt-cls">
                  {showPriceValue(cartLst.cart_item_total_price)}
                </h4>
              </div>
            </div>

            <div className="modifier-name-list">
              {this.showModifiers(cartLst.modifiers)}
            </div>
          </div>
          {/* package div end */}

          <div className="brline"></div>

          {Object.keys(cartLst.addons_setup).length > 0 ? (
            <div className="addon">
              <h4>ADD-ONS</h4>
              {this.addonsSetupLst(cartLst)}
            </div>
          ) : (
            ""
          )}

          <div className="brline"></div>

          {Object.keys(cartLst.setup).length > 0 ? this.setupDet(cartLst) : ""}

          <div className="brline"></div>

          {cartLst.cart_item_special_notes !== ""
            ? this.specialNotes(cartLst)
            : ""}
        </div>
      ));
      return cartlsthtml;
    }
  }

  showModifiers(mdfLst) {
    if (Object.keys(mdfLst).length > 0) {
      const mdflsthtml = mdfLst.map((mdfLt, indx3) => (
        <div key={indx3} className="package-list">
          <p className="one">{stripslashes(mdfLt.cart_modifier_name)}</p>
          <p className="two">{this.showMdfValues(mdfLt.modifiers_values)}</p>
        </div>
      ));
      return mdflsthtml;
    }
  }

  showMdfValues(mdfValLst) {
    var mdfValueText = "";
    if (Object.keys(mdfValLst).length > 0) {
      var tempVl = Object.keys(mdfValLst).length - 1;
      for (var key in mdfValLst) {
        var cartmdfprice =
          parseFloat(mdfValLst[key].cart_modifier_price) > 0
            ? " ( $ " + mdfValLst[key].cart_modifier_price + " )"
            : "";
        mdfValueText +=
          parseInt(tempVl) === parseInt(key)
            ? jsUcfirstFun(stripslashes(mdfValLst[key].cart_modifier_name)) +
              cartmdfprice
            : jsUcfirstFun(stripslashes(mdfValLst[key].cart_modifier_name)) +
              cartmdfprice +
              ", ";
      }
    }
    return mdfValueText;
  }

  addonsSetupLst(cartLstDat) {
    var addonsSet = cartLstDat.addons_setup;
    const addonhtml = addonsSet.map((addonSt, indx4) => (
      <div key={indx4} className="row-replace addon_list">
        <div className="col-sm-cls">
          <h5>
            <span className="title">{addonSt.cart_addon_setup_title}</span>
          </h5>
          {this.addonsSetupValues(addonSt.addons_setup_values)}
        </div>
        <div className="col-sm-cls">
          <div className="amt">
            <span>{showPriceValue(addonSt.cat_addon_setup_amount)}</span>
          </div>
        </div>
      </div>
    ));
    return addonhtml;
  }

  addonsSetupValues(addonsVal) {
    if (Object.keys(addonsVal).length > 0) {
      const addonValhtml = addonsVal.map((addonVl, indx5) => (
        <p key={indx5}>
          {addonVl.cart_addon_setup_val_title} (
          {addonVl.cart_addon_setup_val_qty}X)
        </p>
      ));
      return addonValhtml;
    }
  }

  setupDet(cartLstDat) {
    var setupDt = cartLstDat.setup;
    var setupLbl = "";
    var setupPrice = 0;

    for (var key in setupDt) {
      setupLbl = setupDt[key].cart_setup_type;
      setupPrice += parseFloat(setupDt[key].cart_setup_tatalprice);
    }

    return (
      <div className="buffet_setup">
        <div className="row-replace">
          <div className="col-sm-cls">
            <h5>
              <span className="title">{setupLbl} Setup</span>
            </h5>
          </div>
          <div className="col-sm-cls">
            <div className="amt">
              <span>{showPriceValue(setupPrice)}</span>
            </div>
          </div>
        </div>
        <div className="row">{this.setupList(setupDt)}</div>
      </div>
    );
  }

  setupList(setupDats) {
    if (Object.keys(setupDats).length > 0) {
      const setupDathtml = setupDats.map((setupVl, indx6) => (
        <p key={indx6}>
          {stripslashes(setupVl.cart_setup_name)} :{" "}
          {stripslashes(setupVl.cart_setup_description)}
        </p>
      ));
      return setupDathtml;
    }
  }

  specialNotes(cartLstDat) {
    var special_not = cartLstDat.cart_item_special_notes;

    return (
      <div className="buffet_setup" style={{ paddingTop: "0px" }}>
        <div className="row-replace">
          <div className="col-sm-cls ">
            <h5>
              <a href="">
                <span className="title">Special Notes</span>
              </a>
            </h5>
          </div>
          <div className="col-sm-cls"></div>
        </div>
        <div className="row">
          <p>{special_not}</p>
        </div>
      </div>
    );
  }

  cartHallData() {
    var cartDetailsMn = this.state.popupcartDetailsMain;
    if (Object.keys(cartDetailsMn).length > 0) {
      if (cartDetailsMn.cart_venue_type === "hall") {
        return (
          <div className="panel panel-default">
            <div
              className="panel-heading"
              role="tab"
              id="headingTbH1"
              onClick={this.handlerActTab.bind(this, "H1")}
            >
              <h4 className="panel-title">
                <a
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordion"
                  aria-expanded="false"
                  href="#collapseH1"
                  className="collapsed"
                >
                  Catering Hall Details
                </a>
              </h4>
            </div>
            <div
              id="collapseH1"
              className="panel-collapse fixed-height-cls collapse mCustomScrollbar"
              aria-expanded="false"
              href="#collapseH1"
            >
              <div className="panel-body">
                <div className="buffet_setup" style={{ padding: "0px" }}>
                  <div className="row-replace">
                    <div className="col-sm-cls">
                      <h5>
                        <a href="javascript:void(0)">
                          <span className="title">
                            {cookie.load("cateringHallName")}
                          </span>
                        </a>
                      </h5>
                      <div style={{ paddingLeft: "35px" }}>
                        {cookie.load("cateringHalladdress")}
                      </div>
                    </div>
                    <div className="col-sm-cls">
                      <div className="amt">
                        <span>
                          {showPriceValue(cartDetailsMn.cart_hall_charges)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }

  getCartTotalDet() {
    var cartDeta = this.state.popupcartDetailsMain;
    if (Object.keys(cartDeta).length > 0) {
      return (
        <div className="total_amt">
          <div className="subtotal">
            <div className="col-sm-cls">SUBTOTAL</div>
            <div className="col-sm-cls rt">
              {showPriceValue(cartDeta.cart_sub_total)}
            </div>
          </div>
        </div>
      );
    }
  }

  componentDidMount() {}

  gotoChkFun(flgTxt) {
    var userIdTxt =
      typeof cookie.load("UserId") === "undefined" ? "" : cookie.load("UserId");
    if (flgTxt === "yes") {
      if (userIdTxt !== "") {
        smoothScroll(100, 70);
      }
      this.props.setPropActiveTabFun(4);
    }
    $(".modal-backdrop").remove();
    $("#CartListMdl").modal("hide");
  }

  /* ViewProducts */

  render() {
    return (
      <div>
        <div
          className="modal fade commom-modal-topcls packagemdlCls"
          id="CartListMdl"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-body">
                <div className="common-modal-head">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    X
                  </button>
                  <div className="Cart-list-mdlheader">
                    <h2>Your Cart Details</h2>
                  </div>
                </div>

                {/* modal-items-box start */}
                <div className="modal-items-box">
                  {/* cart details start */}
                  <div className="order_details">
                    <div
                      className="panel-group"
                      id="accordion"
                      role="tablist"
                      aria-multiselectable="true"
                    >
                      {this.getCartDetList()}
                      {this.cartHallData()}
                    </div>
                  </div>
                  {/* cart details end */}

                  {this.getCartTotalDet()}
                </div>
                {/* modal-items-box end */}

                <div className="goto-chkpage-cls">
                  <a
                    href="javascript:void(0)"
                    className="button"
                    onClick={this.gotoChkFun.bind(this, "no")}
                    title="Add More Packages"
                  >
                    Add More Packages
                  </a>

                  <a
                    href="javascript:void(0)"
                    onClick={this.gotoChkFun.bind(this, "yes")}
                    className="button"
                    title="Continue"
                  >
                    Continue To Checkout
                  </a>
                </div>
              </div>

              {/* <div id="dvLoading" className="editDivLoadingCls"></div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
