/* eslint-disable */
import React, { Component } from "react";
import Axios from "axios";
import { appId, apiUrl } from "../Helpers/Config";
/* import modules */
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import innerBanner from "../../common/images/page-banner.jpg";

class Gallery extends Component {
  constructor(props) {
    super(props);
    console.log(this.props, "this.props");
    this.state = {
      cmspage_description: "",
      participating_merchants: [],
      gallery_source: "",
      gallery: [],
      display_gallery: "",
      gallerySlug: this.props.location.pathname,
    };
  }

  componentDidMount() {
    this.loadHappening();
  }
  componentWillReceiveProps(nextProps) {
    if (this.state.gallerySlug !== nextProps.location.pathname) {
      this.setState({ gallerySlug: nextProps.location.pathname }, function() {
        this.loadHappening();
      });
    }
  }
  loadHappening() {
    var gallerySlug = this.state.gallerySlug.replace("/", "");
    console.log(gallerySlug, "gallerySlug");
    Axios.get(
      apiUrl +
        "cms/whats_happening?app_id=" +
        appId +
        "&gallery=yes&gallery_slug=" +
        gallerySlug
    ).then((res) => {
      if (res.data.status === "ok") {
        let gallery_source = res.data.common
          ? res.data.common.image_source
          : "";
        let gallery = res.data.result_set;

        this.setState(
          {
            gallery_source: gallery_source,
            gallery: gallery,
          },
          function() {
            $("#dvLoading").fadeOut();
            this.displaygallery();
          }
        );
      } else {
        $("#dvLoading").fadeOut();
      }
    });
  }
  displaygallery() {
    var displaygallery = "";
    if (this.state.gallery.length > 0) {
      displaygallery = this.state.gallery.map((item) => {
        if (item.gallery_image.length > 0) {
          return item.gallery_image.map((itemImg, indexImg) => {
            return (
              <li key={indexImg}>
                <div className="panel panel-default fixed-width center-block">
                  <div className="panel-body">
                    <a
                      href={this.state.gallery_source + itemImg.gallery_image}
                      className="lightbox-cats"
                    >
                      {" "}
                      <img
                        className="img-responsive center-block"
                        src={this.state.gallery_source + itemImg.gallery_image}
                      />{" "}
                    </a>
                  </div>
                </div>
              </li>
            );
          });
        }
      });
    }

    this.setState({ displaygallery: displaygallery }, function() {
      $(".lightbox-cats").magnificPopup({
        type: "image",
        gallery: { enabled: true },
      });
    });
  }
  render() {
    return (
      <div>
        <Header />
        <div className="common-top-div pagesList-main-div">
          <div className="Pages">
            {/* Header start */}

            {/* Header End */}
          </div>
          <div className="common-inner-blckdiv">
            <div className="page-banner">
              <img src={innerBanner} />
              <div className="inner-banner-content">
                <h2>
                  {this.state.gallerySlug === "/events-gallery" && "Event"}
                  {this.state.gallerySlug === "/food-beverage-gallery" &&
                    "Food Beverage"}
                  {this.state.gallerySlug === "/kakis-gallery" && "Kakis"}
                  {this.state.gallerySlug === "/outlets-gallery" && "Outlet"}
                  {this.state.gallerySlug === "/staff-gallery" && "Staff"}{" "}
                  Gallery
                </h2>
              </div>
            </div>
          </div>
          <div className="cms-page">
            <div className="container">
              <ul className="gallery-list">{this.state.displaygallery}</ul>
            </div>
          </div>
        </div>
        <Footer />
        <div id="dvLoading" className="dvLoadrCls"></div>
      </div>
    );
  }
}

export default Gallery;
