/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import { appId, apiUrl } from "../Helpers/Config";
import innerBanner from "../../common/images/page-banner.jpg";

class Pages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      whatshappenings_source: "",
      whatshappenings: [],
      outletslist: [],
      displaywhatshappenings: "",
      currentTab: "promotions",
      selectedOutlet: "",
    };
  }

  componentDidMount() {
    $(".dvLoadrCls").show();
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.outletslist !== nextProps.outletslist) {
      this.setState({ outletslist: nextProps.outletslist }, function() {
        if (nextProps.outletslist.length > 0) {
          this.setState(
            { selectedOutlet: nextProps.outletslist[0].outlet_id },
            function() {
              this.loadHappening();
            }
          );
        }
      });
    }
  }

  loadHappening() {
    axios.get(apiUrl + "cms/whats_happening?app_id=" + appId).then((res) => {
      if (res.data.status === "ok") {
        let whatshappenings_source = res.data.common
          ? res.data.common.image_source
          : "";
        let whatshappenings = res.data.result_set;

        this.setState(
          {
            whatshappenings_source: whatshappenings_source,
            whatshappenings: whatshappenings,
          },
          function() {
            $("#dvLoading").fadeOut();
            this.displaywhatshappenings();
          }
        );
      } else {
        $("#dvLoading").fadeOut();
      }
    });
  }

  displaywhatshappenings() {
    var displaywhatshappenings = "";
    var totalHappens = 0;
    if (this.state.whatshappenings.length > 0) {
      displaywhatshappenings = this.state.whatshappenings.map((item) => {
        if (item.wh_type === this.state.currentTab) {
          if (item.gallery_image.length > 0) {
            return item.gallery_image.map((itemImg, indexImg) => {
              if (
                itemImg.outlet.length === 1 &&
                itemImg.outlet.indexOf(this.state.selectedOutlet) >= 0
              ) {
                totalHappens++;
                return (
                  <li key={indexImg}>
                    <a
                      href={
                        this.state.whatshappenings_source +
                        itemImg.gallery_image
                      }
                      class="lightbox-cats"
                    >
                      <img
                        src={
                          this.state.whatshappenings_source +
                          itemImg.gallery_image
                        }
                      />{" "}
                    </a>
                  </li>
                );
              }
            });
          }
        }
      });
    }
    if (totalHappens === 0) {
      displaywhatshappenings = (
        <li className="no-happenings">
          No {this.state.currentTab === "promotions" && "Promotions"}
          {this.state.currentTab === "happenings" && "Happenings"} Found
        </li>
      );
    }
    this.setState(
      { displaywhatshappenings: displaywhatshappenings },
      function() {
        $(".lightbox-cats").magnificPopup({
          type: "image",
          gallery: { enabled: true },
        });
      }
    );
  }
  setActiveTab(currentTab) {
    this.setState({ currentTab: currentTab }, function() {
      this.displaywhatshappenings();
    });
  }
  setOutlet(event) {
    this.setState({ selectedOutlet: event.target.value }, function() {
      this.displaywhatshappenings();
    });
  }

  render() {
    $(document).on("click", ".img-responsive", function() {
      var src = $(this).attr("src");

      $(document)
        .find(".carousel-inner")
        .find(".item")
        .removeClass("active");
      $(document)
        .find(".carousel-inner")
        .find('img[src$="' + src + '"]')
        .parent("div")
        .addClass("active");
    });

    return (
      <div>
        {/* Header start */}
        <Header />
        {/* Header End */}
        <div className=" common-top-div pagesList-main-div">
          <div className="common-inner-blckdiv">
            <div className="page-banner">
              <img src={innerBanner} />
              <div className="inner-banner-content">
                <h2>Promos & Happenings</h2>
              </div>
            </div>
          </div>
          <div className="inner-page">
            <div className="container">
              <div className="promo-folio">
                <div className="promo-folio-tab">
                  <div className="tab_sec filter_tabsec">
                    <ul class="nav nav-tabs text-center">
                      {" "}
                      <li
                        className={
                          this.state.currentTab === "promotions" ? "active" : ""
                        }
                      >
                        <a
                          href={void 0}
                          onClick={this.setActiveTab.bind(this, "promotions")}
                        >
                          Promotions
                        </a>
                      </li>
                      <li
                        className={
                          this.state.currentTab === "happenings" ? "active" : ""
                        }
                      >
                        <a
                          href={void 0}
                          onClick={this.setActiveTab.bind(this, "happenings")}
                        >
                          Happenings
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="promo-folio-tab-select">
                    <select onChange={this.setOutlet.bind(this)}>
                      {this.state.outletslist.length > 0 &&
                        this.state.outletslist.map((item, index) => {
                          return (
                            <option value={item.outlet_id} key={index}>
                              {item.outlet_name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="promo-folio-content">
                  <ul>{this.state.displaywhatshappenings}</ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
        <div id="dvLoading" className="dvLoadrCls"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var outletsArr = Array();
  if (Object.keys(state.outlets).length > 0) {
    if (state.outlets[0].status === "ok") {
      outletsArr = state.outlets[0].result_set;
    }
  }
  return {
    outletslist: outletsArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

Pages.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pages));
