/* eslint-disable */
import React, { Component } from "react";

import Axios from "axios";
import Parser from "html-react-parser";

import { baseUrl, appId, apiUrl, timThumpUrl } from "../Helpers/Config";
import { callImage, stripslashes } from "../Helpers/SettingHelper";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";

/* import modules */
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import innerBanner from "../../common/images/page-banner.jpg";

class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cmspage_description: "",
      participating_merchants: [],
              whatshappenings: [],
        whatshappenings_source: "",
    };
  }

  componentDidMount() {
    $(".dvLoadrCls").show();

    Axios.get(apiUrl + 'cms/whats_happening?our_photos=yes&gallery_slug=our-photos&app_id='+appId).then(res => {

        if (res.data.status === "ok") {

           $('.dvLoadrCls').fadeOut(500);

          let whatshappenings_source = res.data.common?res.data.common.image_source:'';
          let whatshappenings = res.data.result_set;

          this.setState({whatshappenings_source:whatshappenings_source, whatshappenings:whatshappenings, page_slug: whatshappenings.wh_name })
          if(Object.keys(whatshappenings).length > 0) {
             this.setState({page_slug: whatshappenings[0].wh_name})
          }


        } else if (res.data.status === "error") {
             $('.dvLoadrCls').fadeOut(500);
        }

        return false;

      });

    $(".dvLoadrCls").fadeOut(500);
  }

      listwhatshappenings() {

      let whatshappenings = this.state.whatshappenings;
      let whatshappenings_source = this.state.whatshappenings_source;
      

      if(Object.keys(whatshappenings).length > 0) {
      const mainMenu = whatshappenings.map((loaddata, index) => {

            return (<ul className="three-boxes" key={'wh'+(index+1)}>
                  {loaddata.gallery_image.map((loaddatagall, gallindex) => {
                  return(                  
                      <li class="gallery-kakis-box right-bottom">
                        <img src={whatshappenings_source+loaddatagall.gallery_image}/>
                        <div>
                          <p>
                            <a class="order-btn" href={"/gallery/"+loaddatagall.gallery_link}>{loaddatagall.gallery_title}</a></p>
                        </div>
                      </li>
                    );                
                  })}
            </ul>);
        
      });
      
      return mainMenu;
      } else {
      return null;
      }



    }

  render() {
    return (
      <div>
        <Header />
        <div className="common-top-div pagesList-main-div">
          <div className="Pages">
            {/* Header start */}

            {/* Header End */}
          </div>
          <div className="common-inner-blckdiv">
            <div className="page-banner">
              <img src={innerBanner} />
              <div className="inner-banner-content">
                <h2>Gallery</h2>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="container-one">
              <div className="five-boxes">
              {this.listwhatshappenings()}
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <div id="dvLoading" className="dvLoadrCls"></div>
      </div>
    );
  }
}

export default Gallery;
