import React,{Component} from 'react';
import cookie from 'react-cookies';

class Logout extends Component {

    componentWillMount(){
		
        cookie.remove("UserId");
        cookie.remove("UserFname");
        cookie.remove("UserLname");
        cookie.remove("UserMobile");
        cookie.remove("UserEmail");
        cookie.remove('orderPaymentMode');
        cookie.remove('orderOutletId');
        cookie.remove('orderTableNo');
        cookie.remove('product_remarks');
        cookie.remove('orderOutletName');
        cookie.remove('orderZoneId');
        cookie.remove('carttotalitems');
        cookie.remove('cartsubtotal');
        cookie.remove('cartid');
		
        /* Delivery avilablity */
        cookie.remove('DeliveryDate');
        cookie.remove('DeliveryTime');
        cookie.remove('unitNoOne');
        cookie.remove('unitNoTwo');

        cookie.remove('promotion_id');
        cookie.remove('promotion_applied');
        cookie.remove('promotion_code');
        cookie.remove('promotion_delivery_charge_applied');
        cookie.remove('promotion_amount');
        cookie.remove('promotion_category');
        cookie.remove('prmo_type');
        
        /*Remove voucher*/
        cookie.remove('voucher_applied');
        cookie.remove('voucher_code');
        cookie.remove('voucher_amount');
		    
		cookie.remove('cateringPay');
        cookie.remove('orderIdTxt');
        cookie.remove('paymentIdTxt');
		
		cookie.remove("reservation_date");
		cookie.remove("reservation_show_date");
		cookie.remove("reservation_show_time");
		cookie.remove("reservation_time");
		cookie.remove("reservation_time_type");
		cookie.remove("reservation_adult");
		cookie.remove("reservation_child");
		cookie.remove("reservation_outlet_id");
		cookie.remove("reservation_outlet_name");
		cookie.remove("reservation_selected_outlet");
		cookie.remove("reservation_notes");
		cookie.remove("reservation_date_array");
		cookie.remove("reservation_time_flag_next");
		cookie.remove("reservation_time_flag");
		cookie.remove("reservation_date_flag_keySet");
		cookie.remove("reservation_date_flag_dateSet");
		
		this.props.history.push('/');

  }

  render() {
    return (
      <h1 className="loading-text">
        Logging out...
      </h1>
    );
  }
}

export default Logout;