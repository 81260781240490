/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import cookie from "react-cookies";
import moment from "moment";
import { format } from "date-fns";
import axios from "axios";
import {
  showAlert,
  stripslashes,
  getCalculatedAmount,
  showLoader,
  hideLoader,
  getOrderDateTime,
  timeToConv12,
  getPromoCkValue,
  removePromoCkValue,
} from "../Helpers/SettingHelper";
import {
  appId,
  apiUrl,
  deliveryId,
  madbarId,
  cateringId,
  CountryTxt,
} from "../Helpers/Config";

import Parser from "html-react-parser";

import productImg from "../../common/images/noimg-400x400.jpg";
import scootyImg from "../../common/images/scooty.png";
import pickupImg from "../../common/images/takeaway_old-b.png";
import cartMpImg from "../../common/images/cart-map.png";
import shoppingBag from "../../common/images/shopping-bag-dark.svg";

import {
  GET_CART_DETAIL,
  UPDATE_CART_DETAIL,
  DELETE_CART_DETAIL,
  DESTROY_CART_DETAIL,
  GET_CATERINGCART_DETAIL,
} from "../../actions";

class CartSideBar extends Component {
  constructor(props) {
    super(props);

    var servicesurchargeArr = Array();
    servicesurchargeArr["servicechrg_displaylabel"] = "Service Charge";
    servicesurchargeArr["servicechrg_type"] = "percentage";
    servicesurchargeArr["servicechrg_per"] = 0;
    servicesurchargeArr["servicechrg_amount"] = 0;
    servicesurchargeArr["surcharge_amount"] = 0;
    servicesurchargeArr["surcharge_type"] = "";
    servicesurchargeArr["surcharge_date"] = "";
    servicesurchargeArr["surcharge_frmtime"] = "";
    servicesurchargeArr["surcharge_totime"] = "";

    this.state = {
      cartlistdetail: [],
      overAllcart: [],
      cartItems: [],
      cartDetails: [],
      cartStatus: "",
      settings: [],
      cartTotalItmCount: 0,
      cartTotalAmount: 0,
      cateringCartItems: [],
      cateringCartDetails: [],
      cateringCartTotalItmCount: 0,
      updateCartResponse: [],
      startMsg: 0,
      servicesurchargeval: servicesurchargeArr,
    };
  }

  componentWillMount() {
    var avltyTxt = cookie.load("defaultAvilablityId");
    if (avltyTxt === cateringId) {
      this.props.getCateringCartDetail();
    } else {
      this.props.getCartDetail();
    }

    var orderTAT =
      typeof cookie.load("orderTAT") === "undefined"
        ? ""
        : cookie.load("orderTAT");
    var orderDateTmTxt = getOrderDateTime("", orderTAT);
    var formatedDate = format(orderDateTmTxt, "yyyy-MM-dd");
    var OrderHours = orderDateTmTxt.getHours();
    var OrderMunts = orderDateTmTxt.getMinutes();
    var orderTime = this.pad(OrderHours) + ":" + this.pad(OrderMunts);
    var OrdDateTimeArr = Array();
    OrdDateTimeArr["OrdDate"] = formatedDate;
    OrdDateTimeArr["OrdTime"] = orderTime;
    this.getServiceChargeAmt(OrdDateTimeArr);
  }

  componentWillReceiveProps(headerProps) {
    if (headerProps.cartTriggerFlg === "yes") {
      headerProps.prpSateValChange("cartflg", "no");
      this.props.getCartDetail();
    }
    hideLoader("product-details", "class");
    hideLoader("cart_body", "class");

    if (this.state.updateCartResponse !== headerProps.updateCartResponse) {
      if (Object.keys(headerProps.updateCartResponse).length > 0) {
        this.setState(
          { updateCartResponse: headerProps.updateCartResponse },
          function() {
            var Response = headerProps.updateCartResponse;

            if (Object.keys(Response).length > 0) {
              if (Response[0].status === "error") {
                if (this.state.startMsg === 1) {
                  this.handleShowAlertFunct("Error", Response[0].message);
                  this.setState({ startMsg: 0 });
                }
              }
            }
          }
        );
      }
    }
  }

  getServiceChargeAmt(OrdDateTimeArr) {
    var availabilityIdTxt = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    var orderDate = "";
    var orderTime = "";

    orderDate = OrdDateTimeArr["OrdDate"];
    orderTime = OrdDateTimeArr["OrdTime"];

    var servicesurchargeArr = this.state.servicesurchargeval;
    var online_servicecharge_type = servicesurchargeArr["servicechrg_type"];
    var onlineservice_displaylabel =
      servicesurchargeArr["servicechrg_displaylabel"];
    var online_servicecharge_per = 0,
      online_servicecharge_amount = 0,
      subchr_amount = 0;
    var subchr_type = "",
      subchr_date = "",
      subchr_frmtime = "",
      subchr_totime = "";

    var urlPrms =
      "&availabilityid=" +
      availabilityIdTxt +
      "&outletid=" +
      orderOutletId +
      "&orderdate=" +
      orderDate +
      "&ordertime=" +
      orderTime +
      "&";
    this.props.updateCartDetail();
    var urlShringTxt =
      apiUrl + "outlets/outletsubchargenew?app_id=" + appId + urlPrms;
    axios.get(urlShringTxt).then((res) => {
      if (res.data.status === "ok") {
        online_servicecharge_per =
          res.data.online_service_charge !== ""
            ? parseFloat(res.data.online_service_charge)
            : 0;
        online_servicecharge_amount = 0;

        if (res.data.subcharge_apply === "Yes") {
          var subchr_data = res.data.result_set[0];
          if (res.data.subcharge_apply === "Yes") {
            subchr_amount =
              subchr_data.subchr_value !== ""
                ? parseFloat(subchr_data.subchr_value)
                : 0;
            subchr_type = subchr_data.subchr_type;
            if (subchr_data.subchr_type === "Time") {
              subchr_frmtime = this.tmConv24(subchr_data.subchr_from_time);
              subchr_totime = this.tmConv24(subchr_data.subchr_to_time);
            } else {
              subchr_date = subchr_data.subchr_date;
            }
          }
        }
      }

      servicesurchargeArr["servicechrg_displaylabel"] =
        res.data.onlineservice_displaylbl;
      servicesurchargeArr["servicechrg_type"] = res.data.onlineservice_type;
      servicesurchargeArr["servicechrg_per"] = res.data.online_service_charge;
      servicesurchargeArr["surcharge_amount"] = subchr_amount;
      servicesurchargeArr["surcharge_type"] = subchr_type;
      servicesurchargeArr["surcharge_date"] = subchr_date;
      servicesurchargeArr["surcharge_frmtime"] = subchr_frmtime;
      servicesurchargeArr["surcharge_totime"] = subchr_totime;

      this.setState({ servicesurchargeval: servicesurchargeArr });
    });
  }
  pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }
  tmConv24(time24) {
    var ts = time24;
    if (ts !== "") {
      var H = +ts.substr(0, 2);
      var h = H % 12 || 12;
      h = h < 10 ? "0" + h : h;
      var ampm = H < 12 ? " AM" : " PM";
      ts = h + ts.substr(2, 3) + ampm;
    }
    return ts;
  }

  handleShowAlertFunct(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  componentDidMount() {}

  removePromoFun() {
    removePromoCkValue();
    this.props.getCartDetail();
  }

  cartDetailsList() {
    var cartItemsArr = this.props.cartItems;

    if (Object.keys(cartItemsArr).length > 0) {
      var cartDetailsArr = this.props.cartDetails;
      var promoTionArr = getPromoCkValue();

      var globalSettings = Array();
      if (Object.keys(this.props.globalsettings).length > 0) {
        if (this.props.globalsettings[0].status === "ok") {
          globalSettings = this.props.globalsettings[0].result_set;
        }
      }
      /*var calculatedAmount = getCalculatedAmount(globalSettings,cartDetailsArr,promoTionArr);*/

      var zoneDetails = this.props.zonedetails;
      var serviceSubChrArr = this.state.servicesurchargeval;
      var calculatedAmount = getCalculatedAmount(
        globalSettings,
        zoneDetails,
        cartDetailsArr,
        promoTionArr,
        serviceSubChrArr
      );

      var orderDateTime =
        typeof cookie.load("orderDateTime") === "undefined"
          ? ""
          : cookie.load("orderDateTime");
      var orderTAT =
        typeof cookie.load("orderTAT") === "undefined"
          ? ""
          : cookie.load("orderTAT");
      var orderDateTmTxt = getOrderDateTime(orderDateTime, orderTAT);
      var orderTmTxt = cookie.load("deliveryTime");
      var orderstTmTxt = cookie.load("orderSlotStrTime");
      var orderenTmTxt = cookie.load("orderSlotEndTime");

      return (
        <div className="hcart_dropdown">
          <div className="hcart_tt">
            <div className="row-replace">
              <div className="col-sm-cls text-left">
                <h3>YOUR ORDER DETAILS</h3>
              </div>
            </div>
          </div>
          <div className="hcart_scrollarea">
            <div className="cart_table">
              <div className="cart_body">
                {cookie.load("defaultAvilablityId") === deliveryId ||
                cookie.load("defaultAvilablityId") === madbarId ? (
                  <div className="delivery-cart-div">
                    <div className="cart-direction">
                      <img className="cart-direction-left" src={scootyImg} />
                      <img className="cart-direction-right" src={cartMpImg} />
                    </div>
                    <div className="cart_row cart-header-first">
                      <div className="row-replace">
                        <div className="col-sm-cls cart_left text-left">
                          <h4>Order Handled By</h4>
                          <p>{cookie.load("orderOutletName")}</p>
                          <p>{cookie.load("orderHandledByText")}</p>
                        </div>
                        <div className="col-sm-cls cart_right text-right">
                          <h4>Delivery Location</h4>
                          <p>{cookie.load("orderDeliveryAddress")}</p>
                          <p>
                            {CountryTxt} {cookie.load("orderPostalCode")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="pickup-cart-div">
                    <div className="cart-direction">
                      <img className="cart-direction-left" src={pickupImg} />
                    </div>
                    <div className="cart_row cart-header-first">
                      <div className="row-replace">
                        <div className="col-xs-12 cart_left text-center">
                          <h4>Pickup Location</h4>
                          <p>{cookie.load("orderOutletName")}</p>
                          <p>{cookie.load("orderHandledByText")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="cart_row cart-header-second">
                  <div className="row-replace">
                    <div className="col-sm-cls text-left">
                      <h5>
                        {cookie.load("defaultAvilablityId") === deliveryId ||
                        cookie.load("defaultAvilablityId") === madbarId
                          ? "Delivery"
                          : "Pickup"}{" "}
                        Date
                      </h5>
                      <h3>{moment(orderDateTmTxt).format("DD/MM/YYYY")}</h3>
                    </div>
                    <div className="col-sm-cls text-right">
                      <h5>
                        {cookie.load("defaultAvilablityId") === deliveryId ||
                        cookie.load("defaultAvilablityId") === madbarId
                          ? "Delivery"
                          : "Pickup"}{" "}
                        Time
                      </h5>
                      {typeof orderstTmTxt != "undefined" &&
                      orderstTmTxt != "" ? (
                        <h3>
                          {timeToConv12(orderstTmTxt)} -{" "}
                          {timeToConv12(orderenTmTxt)}
                        </h3>
                      ) : (
                        <h3>{moment(orderDateTmTxt).format("hh : mm A")}</h3>
                      )}
                    </div>
                  </div>
                </div>
                <div className="hcart_tt">
                  <div className="row-replace">
                    <div className="col-sm-cls text-left">
                      <h3>Your Items</h3>
                    </div>
                    <div className="col-sm-cls text-right">
                      <a
                        href="javascript:void(0)"
                        onClick={this.clearCartItm.bind(this)}
                        className="hclear_cart"
                        title="CLEAR CART"
                      >
                        CLEAR CART
                      </a>
                    </div>
                  </div>
                </div>

                {this.cartItemList()}
              </div>

              <div className="cart_footer">
                <div className="cart_row">
                  <div className="row-replace">
                    <div className="col-sm-cls text-left">
                      <p className="text-uppercase">SUBTOTAL</p>
                    </div>
                    <div className="col-sm-cls text-right">
                      <span>${calculatedAmount["cartSubTotalAmount"]}</span>
                    </div>
                  </div>
                </div>
                {parseFloat(calculatedAmount["deliveryCharge"]) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">Delivery</p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          $
                          {parseFloat(
                            calculatedAmount["deliveryCharge"]
                          ).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {parseFloat(calculatedAmount["additionalDelivery"]) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">Additional Delivery</p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          $
                          {parseFloat(
                            calculatedAmount["additionalDelivery"]
                          ).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {parseFloat(calculatedAmount["serviceCharge"]) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-xs-cls">
                        {calculatedAmount["servicechrgType"] === "fixed" ? (
                          <p className="text-uppercase">
                            {calculatedAmount["servicechrgDisplaylbl"] !== ""
                              ? calculatedAmount["servicechrgDisplaylbl"]
                              : "Service Charge"}
                          </p>
                        ) : (
                          <p className="text-uppercase">
                            {calculatedAmount["servicechrgDisplaylbl"] !== ""
                              ? calculatedAmount["servicechrgDisplaylbl"]
                              : "Service Charge"}{" "}
                            ({calculatedAmount["servicechargePer"]}%)
                          </p>
                        )}
                      </div>
                      <div className="col-xs-cls text-right">
                        <span>
                          $
                          {parseFloat(
                            calculatedAmount["serviceCharge"]
                          ).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {parseFloat(calculatedAmount["packagingCharge"]) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-xs-cls">
                        <p className="text-uppercase">Packaging Charges</p>
                      </div>
                      <div className="col-xs-cls text-right">
                        <span>
                          $
                          {parseFloat(
                            calculatedAmount["packagingCharge"]
                          ).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {parseFloat(calculatedAmount["promotionAmount"]) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">Promo Code</p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          -($
                          {parseFloat(
                            calculatedAmount["promotionAmount"]
                          ).toFixed(2)}
                          )
                        </span>
                      </div>
                    </div>
                    <a
                      href="javascript:void(0)"
                      onClick={this.removePromoFun.bind(this)}
                      className="cart_remove"
                    ></a>
                  </div>
                )}
                {parseFloat(calculatedAmount["orderGstAmount"]) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">
                          GST ({calculatedAmount["orderDisplayGst"]} %)
                        </p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          $
                          {parseFloat(
                            calculatedAmount["orderGstAmount"]
                          ).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {parseFloat(calculatedAmount["voucherDiscountAmount"]) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">
                          Voucher Disocunt Amount
                        </p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          $
                          {parseFloat(
                            calculatedAmount["voucherDiscountAmount"]
                          ).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                <div className="cart_row grant-total-cls">
                  <div className="row-replace">
                    <div className="col-sm-cls text-left">
                      <p className="text-uppercase">Total</p>
                    </div>
                    <div className="col-sm-cls text-right">
                      <span>
                        <sup>$</sup>
                        {calculatedAmount["grandTotalAmount"]}
                      </span>
                    </div>
                  </div>
                </div>

                {(cookie.load("defaultAvilablityId") === deliveryId ||
                  cookie.load("defaultAvilablityId") === madbarId) &&
                  this.loadDeliveryPercentage()}

                {parseFloat(cartDetailsArr.cart_special_discount) > 0 && (
                  <div class="member-discount-total">
                    * {cartDetailsArr.cart_special_discount_type} $
                    {cartDetailsArr.cart_special_discount} Applied
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="cart_action">
            <div className="cartaction_bottom">
              <Link
                to={"/checkout"}
                className="btn btn_pink cartaction_checkout"
                title="Checkout Now"
              >
                Checkout
              </Link>
            </div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  /* this  function used to load delivery percentage */
  loadDeliveryPercentage() {
    var freeDeliveryAmnt = 0;
    var DeliveryAmnt = 0;
    var remainAmnt = 0;
    var delPercentage = 0;

    var cartItemsArr = this.props.cartItems;

    if (Object.keys(cartItemsArr).length > 0) {
      var cartDetailsArr = this.props.cartDetails;
      var promoTionArr = getPromoCkValue();

      var globalSettings = Array();
      if (Object.keys(this.props.globalsettings).length > 0) {
        if (this.props.globalsettings[0].status === "ok") {
          globalSettings = this.props.globalsettings[0].result_set;
        }
      }

      var zoneDetails = this.props.zonedetails;
      var serviceSubChrArr = this.state.servicesurchargeval;
      var calculatedAmount = getCalculatedAmount(
        globalSettings,
        zoneDetails,
        cartDetailsArr,
        promoTionArr,
        serviceSubChrArr
      );

      freeDeliveryAmnt = parseFloat(calculatedAmount["freeDeliveryAmnt"]);
      DeliveryAmnt = parseFloat(calculatedAmount["deliveryCharge"]);
      var subTotal = parseFloat(calculatedAmount["cartSubTotalAmount"]);
      var percentage = (subTotal * 100) / freeDeliveryAmnt;
      percentage = Math.round(percentage);
      delPercentage = percentage;
      remainAmnt = parseFloat(freeDeliveryAmnt - subTotal).toFixed(2);
    }

    if (DeliveryAmnt > 0 && freeDeliveryAmnt > 0 && remainAmnt > 0) {
      return (
        <div className="cart_row progress_bar_div">
          <div className="indication">
            <div className="indication_progress">
              <span
                className="progress_bar"
                style={{ width: delPercentage + "%" }}
              />
            </div>
            <p className="help-block">${remainAmnt} more to FREE delivery!</p>
          </div>
        </div>
      );
    }
  }

  cartItemList() {
    var cartItemsArr = this.props.cartItems;
    if (Object.keys(cartItemsArr).length > 0) {
      return cartItemsArr.map((product, index) => (
        <div
          className="cart_row product-details"
          id={"rowcartid-" + product.cart_item_id}
          key={index}
        >
          <div className="row-replace">
            <div className="col-sm-cls cart_left">
              <div className="cart_img">
                <a href="javascript:void(0)" title="">
                  {product.cart_item_product_image !== "" ? (
                    <img
                      src={product.cart_item_product_image}
                      width="110"
                      height="67"
                      alt=""
                    />
                  ) : (
                    <img src={productImg} width="110" height="67" alt="" />
                  )}
                </a>
              </div>
              <div className="cart_info text-left">
                <h4>{stripslashes(product.cart_item_product_name)}</h4>
                <h4>
                  {product.cart_item_voucher_id !== "" &&
                  product.cart_item_voucher_id != null
                    ? "Discount Applied"
                    : ""}{" "}
                </h4>
                {this.loadModifierItems(
                  product.cart_item_type,
                  product.modifiers,
                  product.set_menu_component
                )}

                {product.cart_item_special_notes !== "" && (
                  <p className="help-block">
                    {stripslashes(product.cart_item_special_notes)}
                  </p>
                )}

                {parseFloat(product.cart_item_promotion_discount) > 0 && (
                  <span class="member-discount-desc">
                    $ {product.cart_item_promotion_discount}{" "}
                    {product.cart_item_promotion_type} discount Applied
                  </span>
                )}
              </div>
            </div>
            <div className="col-sm-cls cart_right text-right">
              <div className="cart_price">
                <p>${product.cart_item_total_price}</p>
              </div>

              {product.cart_item_voucher_product_free != 1 ? (
                <div className="qty_bx">
                  <span
                    className="qty_minus"
                    onClick={this.updateCartQty.bind(this, product, "decr")}
                  >
                    -
                  </span>
                  <input type="text" value={product.cart_item_qty} readOnly />
                  <span
                    className="qty_plus"
                    onClick={this.updateCartQty.bind(this, product, "incr")}
                  >
                    +
                  </span>
                </div>
              ) : (
                <div className="qty_bx free_product">
                  <span className="qty_minus"></span>
                  <input type="text" value={product.cart_item_qty} readOnly />
                  <span className="qty_plus"></span>
                </div>
              )}
            </div>
          </div>
          <a
            href="javascript:void(0)"
            onClick={this.deleteCartItm.bind(this, product)}
            className="cart_remove"
          ></a>
        </div>
      ));
    } else {
      return "";
    }
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length;
    var comboLen = combo.length;
    var html = '<p class="cart_extrainfo">';

    var temp_html = "";

    if (itemType === "Modifier" && len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modVlPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModVlPrice = modVlPrice > 0 ? " (+$" + modVlPrice + ")" : "";
        temp_html +=
          "<span>" +
          modName +
          ":</span> <span>" +
          modval +
          newModVlPrice +
          "</span> ";
      }

      html += temp_html + "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (itemType === "Component" && comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[i]["product_details"]);

        if (combo[i]["product_details"][0]["modifiers"].length) {
          html +=
            "<p class='cart_extrainfo'>" +
            comboName +
            ": <span>" +
            comboVal +
            " " +
            this.showComboModifiers(
              combo[i]["product_details"][0]["modifiers"]
            ) +
            "</span></p> ";
        } else {
          html +=
            "<p class='cart_extrainfo'>" +
            comboName +
            ": <span>" +
            comboVal +
            " " +
            this.showComboModifiers(
              combo[i]["product_details"][0]["modifiers"]
            ) +
            "</span> </p>";
        }
      }
      html += "</p>";
      var reactElement = Parser(html);
      return reactElement;
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        if (parseInt(combos[r]["cart_menu_component_product_qty"]) > 0) {
          var comboPro = combos[r]["cart_menu_component_product_name"];
          var comboQty = combos[r]["cart_menu_component_product_qty"];
          var comboPrice = combos[r]["cart_menu_component_product_price"];
          var newPrice = comboPrice > 0 ? " (+$" + comboPrice + ")" : "";
          html += "<p>" + comboQty + " X " + comboPro + newPrice + " </p> ";
        }
      }
      return html;
    }
    return "";
  }

  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length;
    var html = "<div >";
    if (lenMod > 0) {
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modValPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModValPrice = modValPrice > 0 ? " (+$" + modValPrice + ")" : "";
        html +=
          "<p><b>" +
          modName +
          ":</b> </p><p> " +
          modval +
          newModValPrice +
          "</p> ";
      }
      html += "</div>";

      return html;
    }

    return "";
  }

  updateCartQty(itemArr, type) {
    var productId = itemArr.cart_item_product_id;
    var cartItemId = itemArr.cart_item_id;
    var cartQty = itemArr.cart_item_qty;
    var totalItmCount = this.props.cartTotalItmCount;
    var orderVoucherId = itemArr.cart_voucher_order_item_id;

    showLoader("rowcartid-" + cartItemId, "Idtext");

    if (type === "decr") {
      cartQty = parseInt(cartQty) - 1;
      if (parseInt(totalItmCount) === 0) {
      } else if (parseInt(cartQty) === 0) {
        this.props.deleteCartDetail(cartItemId);
      } else {
        this.props.updateCartDetail(
          productId,
          cartItemId,
          cartQty,
          orderVoucherId
        );
      }
    } else {
      cartQty = parseInt(cartQty) + 1;
      this.props.updateCartDetail(
        productId,
        cartItemId,
        cartQty,
        orderVoucherId
      );
    }
    this.setState({ startMsg: 1 });
    removePromoCkValue();
  }

  deleteCartItm(itemArr) {
    var cartItemId = itemArr.cart_item_id;
    showLoader("rowcartid-" + cartItemId, "Idtext");
    this.props.deleteCartDetail(cartItemId);
    removePromoCkValue();
  }

  clearCartItm() {
    showLoader("cart_body", "class");
    this.props.destroyCartDetail();
    removePromoCkValue();
  }

  gotoCateringChkut() {
    var cartTotal = this.props.cateringCartTotalItmCount;
    /*if(parseFloat(cartTotal)>0) {*/
    cookie.save("cateringCheckoutFromCart", "Yes");
    this.props.history.push("/catering");
    /*}*/
  }

  render() {
    var currenturl = window.location.href;
    var substring1 = "checkout";

    var avltyTxt = cookie.load("defaultAvilablityId");

    return (
      <li className="htico_cart">
        {avltyTxt === cateringId ? (
          <a
            href={void 0}
            onClick={this.gotoCateringChkut.bind(this)}
            className="hcartdd_trigger"
            title=""
          >
            <img src={shoppingBag} />
            <span className="hcart_round">
              {this.props.cateringCartTotalItmCount}
            </span>
          </a>
        ) : (
          <a href={void 0} className="hcartdd_trigger" title="">
            <img src={shoppingBag} />
            <span className="hcart_round">{this.props.cartTotalItmCount}</span>
          </a>
        )}
        {currenturl.includes(substring1) !== true && avltyTxt !== cateringId
          ? this.cartDetailsList()
          : ""}
      </li>
    );
  }
}

const mapStateTopProps = (state) => {
  var overAllcart = Array();
  var cartDetails = Array();
  var cartItems = Array();
  var updateCartResponse = Array();
  var cartTotalItmCount = 0;
  var cartStatus = "";
  if (Object.keys(state.cartlistdetail).length > 0) {
    var resultSetArr = !("result_set" in state.cartlistdetail[0])
      ? Array()
      : state.cartlistdetail[0].result_set;
    if (
      state.cartlistdetail[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      overAllcart = resultSetArr;
      cartDetails = resultSetArr.cart_details;
      cartItems = resultSetArr.cart_items;
      cartTotalItmCount = resultSetArr.cart_details.cart_total_items;
      cartStatus = "success";
    } else {
      cartStatus = "failure";
    }
  }

  if (Object.keys(state.updatecartdetail).length > 0) {
    if (state.updatecartdetail[0].status === "error") {
      updateCartResponse = state.updatecartdetail;
    }
  }

  var cateringCartDetails = Array();
  var cateringCartItems = Array();
  var cateringCartTotalItmCount = 0;
  if (Object.keys(state.cateringcartlistdetail).length > 0) {
    var resultSetArr = !("result_set" in state.cateringcartlistdetail[0])
      ? Array()
      : state.cateringcartlistdetail[0].result_set;
    if (
      state.cateringcartlistdetail[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      cateringCartDetails = resultSetArr.cart_details;
      cateringCartItems = resultSetArr.cart_items;
      cateringCartTotalItmCount = resultSetArr.cart_details.cart_total_items;
    }
  }

  return {
    overAllcart: overAllcart,
    cartDetails: cartDetails,
    cartItems: cartItems,
    cartTotalItmCount: cartTotalItmCount,
    cartStatus: cartStatus,
    cateringCartDetails: cateringCartDetails,
    cateringCartItems: cateringCartItems,
    cateringCartTotalItmCount: cateringCartTotalItmCount,
    updateCartResponse: updateCartResponse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCartDetail: () => {
      dispatch({ type: GET_CART_DETAIL });
    },
    updateCartDetail: (productId, cartItemId, cartQty, orderVoucherId) => {
      dispatch({
        type: UPDATE_CART_DETAIL,
        productId,
        cartItemId,
        cartQty,
        orderVoucherId,
      });
    },
    deleteCartDetail: (cartItemId) => {
      dispatch({ type: DELETE_CART_DETAIL, cartItemId });
    },
    destroyCartDetail: () => {
      dispatch({ type: DESTROY_CART_DETAIL });
    },
    getCateringCartDetail: () => {
      dispatch({ type: GET_CATERINGCART_DETAIL });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(CartSideBar);
