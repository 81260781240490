/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import Parser from "html-react-parser";

import { appId, apiUrl } from "../Helpers/Config";
import { stripslashes } from "../Helpers/SettingHelper";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";

/* import modules */
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import innerBanner from "../../common/images/page-banner.jpg";

class Partnermerchant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cmspage_description: "",
      participating_merchants: [],
      whatshappenings_source: "",
      whatshappenings: [],
      merchantHeader: "",
      merchant_content: "",
      activeIndex: 0,
    };
  }

  componentDidMount() {
    this.loadwhatshappening();
    $(".dvLoadrCls").show();

    let requestOne = Axios.get(
      apiUrl + "cms/participating_merchants?app_id=" + appId
    );
    let requestTwo = Axios.get(
      apiUrl + "cms/page?app_id=" + appId + "&page_slug=participating-merchants"
    );

    Axios.all([requestOne, requestTwo])
      .then(
        Axios.spread((...responses) => {
          $(".dvLoadrCls").fadeOut(500);

          const responseOne = responses[0];
          const responseTwo = responses[1];

          let cmspage_description = "";
          let participating_merchants = [];

          if (responseOne.data.status === "ok") {
            participating_merchants = responseOne.data.result_set;
          }

          if (responseTwo.data.status === "ok") {
            cmspage_description = Parser(
              responseTwo.data.result_set[0].cmspage_description
            );
          }

          this.setState({
            cmspage_description: cmspage_description,
            participating_merchants: participating_merchants,
          });

          // use/access the results
        })
      )
      .catch((errors) => {
        // react on errors.
      });
  }

  loadwhatshappening() {
    Axios.get(
      apiUrl +
        "cms/whats_happening?app_id=" +
        appId +
        "&partner_merchants=yes&gallery=yes"
    ).then((res) => {
      if (res.data.status === "ok") {
        $(".dvLoadrCls").fadeOut(500);

        let whatshappenings_source = res.data.common
          ? res.data.common.image_source
          : "";
        let whatshappenings = res.data.result_set;

        this.setState(
          {
            whatshappenings_source: whatshappenings_source,
            whatshappenings: whatshappenings,
          },
          function() {
            this.listwhatshappenings();
          }
        );
      } else if (res.data.status === "error") {
        $(".dvLoadrCls").fadeOut(500);
      }

      return false;
    });
  }

  listwhatshappenings() {
    let whatshappenings = this.state.whatshappenings;
    let whatshappenings_source = this.state.whatshappenings_source;
    var merchantHeader = "";
    var merchant_content = "";
    if (Object.keys(whatshappenings).length > 0) {
      merchantHeader = whatshappenings.map((loaddata, index) => {
        return (
          <li
            className={this.state.activeIndex === index ? "active" : ""}
            key={index}
            onClick={this.selectIndex.bind(this, index)}
          >
            <a>{loaddata.wh_name}</a>
          </li>
        );
      });
      merchant_content = whatshappenings.map((loaddata, index) => {
        if (this.state.activeIndex == index) {
          if (loaddata.gallery_image.length > 0) {
            return loaddata.gallery_image.map((item, index1) => {
              if (item.gallery_image !== "" && item.gallery_image !== null) {
                console.log(
                  this.state.activeIndex,
                  index,
                  loaddata.gallery_image,
                  item.gallery_image,
                  "indexindex"
                );
                return (
                  <li key={index1}>
                    <div className="merchant-parent">
                      <div className="merchant-img">
                        <div className="merchant-thumb">
                          <Link to={"/" + item.gallery_link}>
                            <img
                              src={whatshappenings_source + item.gallery_image}
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="merchant-info">
                        <h4>
                          <Link to={"/" + item.gallery_link}>
                            {item.gallery_title}
                          </Link>
                        </h4>
                        <Link to={"/" + item.gallery_link} className="button">
                          View More
                        </Link>
                      </div>
                    </div>
                  </li>
                );
              }
            });
          }
        }
      });
    }
    console.log(merchant_content, "merchant_content");
    this.setState({
      merchantHeader: merchantHeader,
      merchant_content: merchant_content,
    });
  }
  selectIndex(index) {
    this.setState({ activeIndex: index }, function() {
      this.listwhatshappenings();
    });
  }

  listaccording() {
    let participating_merchants = this.state.participating_merchants;

    if (Object.keys(participating_merchants).length > 0) {
      const mainMenu = participating_merchants.map((loaddata, index) => {
        return (
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                {stripslashes(loaddata.pam_title)}
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              {Parser(stripslashes(loaddata.pam_description))}
            </AccordionItemPanel>
          </AccordionItem>
        );
      });

      return <Accordion allowZeroExpanded>{mainMenu}</Accordion>;
    } else {
      return null;
    }
  }

  render() {
    return (
      <div>
        <Header />
        <div className=" common-top-div pagesList-main-div">
          <div className="Pages">
            {/* Header start */}

            {/* Header End */}
          </div>
          <div className="common-inner-blckdiv">
            <div className="page-banner">
              <img src={innerBanner} />
              <div className="inner-banner-content">
                <h2>Partners Merchant</h2>
              </div>
            </div>
          </div>
          <div className="container">
            {/*  <div className="participating-merchants">
              {this.state.cmspage_description}
            </div> */}
            <div className="merchants">
              <div className="merchants-tab">
                <div className="tab_sec filter_tabsec">
                  <ul className="nav nav-tabs text-center">
                    {this.state.merchantHeader}
                  </ul>
                </div>
              </div>
              <div className="merchants-info">
                <ul>{this.state.merchant_content}</ul>
              </div>
            </div>

            {/* this.state.participating_merchants.length && (
              <div className="par_mer_inner">
                <Accordion>{this.listaccording()}</Accordion>
              </div>
            ) */}
          </div>
        </div>
        <Footer />
        <div id="dvLoading" className="dvLoadrCls"></div>
      </div>
    );
  }
}

export default Partnermerchant;
