import { takeEvery, call, put } from "redux-saga/effects";
import { GET_OWNVENUE_CATEGORY, SET_OWNVENUE_CATEGORY } from "../actions";
import { appId, apiUrlV2 } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetOwnvenueCategory = function*() {
  yield takeEvery(GET_OWNVENUE_CATEGORY, workerGetOwnvenueCategory);
};

function* workerGetOwnvenueCategory({ proSlug }) {
  try {
    var cateringOutletId = "";
    if (typeof proSlug != "undefined") {
      cateringOutletId = proSlug["catering_outlet_id"];
    }
    const uri =
      apiUrlV2 +
      "catering/categories?app_id=" +
      appId +
      "&cat_venue_type=ownvenue&cat_product_val=no&outlet_id=" +
      cateringOutletId;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_OWNVENUE_CATEGORY, value: resultArr });
  } catch {
    console.log("Get Category Failed");
  }
}
