/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import cookie from "react-cookies";
import Parser from "html-react-parser";
import { GET_SOCIALSETTINGS } from "../../actions";

import footerLogo from "../../common/images/georges-logo.png";
import facebookIcon from "../../common/images/facebook-icon.svg";
import instagramIcon from "../../common/images/instagram-icon.svg";
import youtubeIcon from "../../common/images/youtube-icon.svg";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      footer_contact_info: "",
      footer_about_us: "",
      footerSocialcontent: "",
      footer_menus: "",
      copywrite: "",
    };
  }

  componentDidMount() {
    this.props.getSocialsetting();

    var btn = $("#scrollbutton");

    $(window).scroll(function() {
      if ($(window).scrollTop() > 300) {
        btn.addClass("show");
      } else {
        btn.removeClass("show");
      }
    });

    btn.on("click", function(e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "300");
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.socialsettingData != prevProps.socialsettingData) {
      this.setState({
        footerSocialcontent: this.props.socialsettingData[0].result_set,
      });
    }

    if (prevProps.staticblack !== this.props.staticblack) {
      var footer_contact_info = "";
      var footer_about_us = "";
      var footer_menus = "";
      var copywrite = "";

      if (Object.keys(this.props.staticblack).length > 0) {
        this.props.staticblack.map((data) => {
          if (
            data.staticblocks_slug === "new-georges-footer-contact-info-updated"
          ) {
            footer_contact_info = data.staticblocks_description;
          }

          if (data.staticblocks_slug === "new-georges-footer-about-us") {
            footer_about_us = data.staticblocks_description;
          }

          if (
            data.staticblocks_slug === "new-georges-footer-menus-updated" &&
            cookie.load("UserId")
          ) {
            footer_menus = data.staticblocks_description;
          }
          if (
            data.staticblocks_slug === "new-georges-footer-menus-logged-out" &&
            !cookie.load("UserId")
          ) {
            footer_menus = data.staticblocks_description;
          }
          if (data.staticblocks_slug === "georges-footer") {
            copywrite = data.staticblocks_description;
          }
        });
      }
      footer_contact_info =
        footer_contact_info !== ""
          ? Parser(footer_contact_info)
          : footer_contact_info;
      footer_about_us =
        footer_about_us !== "" ? Parser(footer_about_us) : footer_about_us;
      footer_menus = footer_menus !== "" ? Parser(footer_menus) : footer_menus;
      copywrite = copywrite !== "" ? Parser(copywrite) : copywrite;

      this.setState({
        footer_contact_info: footer_contact_info,
        footer_about_us: footer_about_us,
        footer_menus: footer_menus,
        copywrite: copywrite,
      });
    }
  }

  render() {
    let socialsettingData = [];
    let facebook,
      instagram,
      youtube = "";

    if (this.state.footerSocialcontent) {
      socialsettingData = this.state.footerSocialcontent;

      if (socialsettingData.socailnetwork_facebook_url) {
        facebook = (
          <li>
            <a
              href={socialsettingData.socailnetwork_facebook_url}
              target="_blank"
              rel="noopener noreferrer"
              className=""
            >
              <img src={facebookIcon} />
            </a>
          </li>
        );
      }

      if (socialsettingData.socailnetwork_instagram_url) {
        instagram = (
          <li>
            <a
              href={socialsettingData.socailnetwork_instagram_url}
              target="_blank"
              rel="noopener noreferrer"
              className=""
            >
              <img src={instagramIcon} />
            </a>
          </li>
        );
      }

      if (socialsettingData.socailnetwork_youtube_url) {
        youtube = (
          <li>
            <a
              href={socialsettingData.socailnetwork_youtube_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={youtubeIcon} />
            </a>
          </li>
        );
      }
    }

    $(document).on("click", ".new_login_popup", function() {
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    });

    return (
      <footer className="footer-main">
        <div className="container">
          <div className="footer-top">
            <div className="footer-newletter">
              {this.state.footer_contact_info}
            </div>
            <div className="footer-logo">
              <img src={footerLogo} />
              <br />
              <div className="social-media">
                <ul className="social-list">
                  {facebook}
                  {instagram}
                  {youtube}
                </ul>
              </div>
            </div>
            <div className="footer-nav">{this.state.footer_menus}</div>
          </div>
        </div>
        {this.state.copywrite !== "" && (
          <div className="copyright-section">{this.state.copywrite}</div>
        )}

        <div className="scrolltop" id="scrollbutton">
          <a href={void 0}>
            <span>
              <i className="fa fa-angle-double-up ars"></i>
              <i className="fa fa-angle-up ars1"></i>
              <i className="fa fa-angle-up ars2"></i>
            </span>
          </a>
        </div>
      </footer>
    );
  }
}

const mapStateTopProps = (state) => {
  let blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }
  return {
    staticblack: blacksArr,
    socialsettingData: state.socialsettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSocialsetting: () => {
      dispatch({ type: GET_SOCIALSETTINGS });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Footer);
