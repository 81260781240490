import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_CATEGORY_LIST, SET_CATEGORY_LIST } from '../actions';
import { appId, apiUrlV2 } from "../components/Helpers/Config";
import Axios from 'axios';

export const watchGetCategory = function* () {
  yield takeEvery(GET_CATEGORY_LIST, workerGetCategory);
}

function* workerGetCategory() {
  try {
    const uri = apiUrlV2+'catering/categories?app_id='+appId+"&cat_product_val=no";
    const result = yield call(Axios.get, uri);
	var resultArr = [];
	resultArr.push(result.data);
    yield put({ type: SET_CATEGORY_LIST, value: resultArr });
  } 
  catch {
    console.log('Get Category Failed');
  }
}
