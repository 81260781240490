
/* eslint-disable */
import React, { Component } from 'react';

import cookie from 'react-cookies';

import { hashHistory } from 'react-router';
import {createBrowserHistory as history } from 'history';

import Axios from 'axios';
import Parser from 'html-react-parser';

import Moment from 'moment';

import {  baseUrl, appId, apiUrl,timThumpUrl} from "../Helpers/Config";
import { callImage,stripslashes } from "../Helpers/SettingHelper";

/* import modules */
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import innerBanner from "../../common/images/page-banner.jpg";

class Notifications extends Component {

    constructor(props) {
        super(props);
        this.state = { 
			activities_image_source:'',
			activities_list:''
			};
			
		if (cookie.load('UserId') == '' || cookie.load('UserId') == undefined) {
            props.history.push("/");
        }
 
    }
    
    componentDidMount() {

		 $('.dvLoadrCls').show();

		 Axios.get(apiUrl + 'customer/activity_list?app_id='+appId+'&customer_id='+cookie.load('UserId')).then(res => {

				if (res.data.status === "ok") {
					
					 $('.dvLoadrCls').fadeOut(500);

					let activities_image_source = res.data.common?res.data.common.activities_image_source:'';
					let activities_list = res.data.result_set;

					this.setState({activities_image_source:activities_image_source, activities_list:activities_list })

				} else if (res.data.status === "error") {
						 $('.dvLoadrCls').fadeOut(500);
				}

				return false;

			});

	}
	
	viewMore(redirect_path,act_id)  {
	
	console.log('clicked');
	
		

				Axios.get(apiUrl + 'customer/activity_read_status?app_id='+appId+'&customer_id='+cookie.load('UserId')+'&act_id='+act_id).then(res => {

				if (res.data.status === "ok") {
					
					if(redirect_path!="")
					{
						if(redirect_path=="Order")
						{			

						 this.props.history.push("/myorders");
						 
						}
						else if(redirect_path=="Home")
						{
						  this.props.history.push("/");
						  
						}
						else if(redirect_path=="Promotion")
						{
						  this.props.history.push("/mypromotions");
						  
						}
						else if(redirect_path=="Account")
						{
						  this.props.history.push("/myaccount");

						} else {}
					}


				} else if (res.data.status === "error") {
					
				}

				return false;

			});

	
	
	}
	
	  
	  listactivities() {

		  let activities_list = this.state.activities_list;
		  let activities_image_source = this.state.activities_image_source;

		  
		  if(Object.keys(activities_list).length > 0) {
			const mainMenu = activities_list.map((loaddata, index) => {
				
					return (

					<li key={'notify'+(index+1)}>						
									<div className={"nfc-bx"+((loaddata.act_read_status === '1')?' done-notify':'')}>
									<div className="nfc_left ">
									{ (loaddata.act_image != '') && 
									<img src={activities_image_source+loaddata.act_image} alt="Notifications"/>
									} 
									<div className="nfc-cnt">
									 <h4>
									 <span> {stripslashes(loaddata.act_title)}</span> {(loaddata.act_short_content)?' - '+loaddata.act_short_content:''}
									 </h4>
                                    <p>{stripslashes(loaddata.act_content)}</p>
									</div>
									</div>
									<div className="nfc_right">
									<h6 className="date-time-nfc">{Moment(loaddata.act_created_on).format('DD-M-YYYY')}&nbsp;&nbsp;|&nbsp;&nbsp;{Moment(loaddata.act_created_on).format('h:mm a')}</h6>
									<a className="button btn-nfc-more notification_view_more" onClick={this.viewMore.bind(this,loaddata.act_redirect,loaddata.act_id)} href="javascript:void(0);">View More</a>
									</div>
									</div>
								</li>

					);
				
			});
			
			return mainMenu;
		  } else {
			return null;
		  }
	  }
   
    render() {

	return (<div>

				 <div className="common-top-div pagesList-main-div">
				    <div className="Pages">

					 {/* Header start */}
					 <Header />
					 {/* Header End */}
					 </div>
					 <div className="common-inner-blckdiv">
						<div className="page-banner">
							<img src={innerBanner} />
							<div className="inner-banner-content">
								<h2>Notifications</h2>
							
							</div>
						</div>
				   </div>
					 <div className="container">
						<div className="nfcn-list-items">
						
						
						{(Object.keys(this.state.activities_list).length > 0) ?
							
							<ul>	{this.listactivities()}</ul>
							
							:<div className="no-recrds-found">No Notification found</div>

						}

						</div>		

						
						
					</div>
					
					
					
					</div>
				 
				 <Footer />		
				 <div id="dvLoading" className="dvLoadrCls"></div>
		       </div>);
    }
}
 


export default Notifications;

