/* eslint-disable */
import React, { Component } from "react";
import axios from "axios";
import cookie from "react-cookies";
import { connect } from "react-redux";
import update from "immutability-helper";
import Slider from "react-slick";

import {
  appId,
  apiUrlV2,
  deliveryId,
  pickupId,
  cateringId,
  reservationId,
} from "../Helpers/Config";
import {
  getReferenceID,
  showLoader,
  hideLoader,
  stripslashes,
  showCustomAlert,
  showCartItemCount,
} from "../Helpers/SettingHelper";
import { GET_MENU_NAVIGATION, GET_CATERINGCART_DETAIL } from "../../actions";
const isEqual = require("react-fast-compare");

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Categorylist from "./SubCompo/Categorylist";
import Venuedetails from "./SubCompo/Venuedetails";
import Packagedetails from "./SubCompo/Packagedetails";
import Checkout from "./SubCompo/Checkout";

import CantDlyImg from "../../common/images/Cant_Deliver.png";
var qs = require("qs");

class Catering extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commonTabCls: "resp-tab-item",
      categoryActCls: "active",
      venueDetActCls: "",
      packageActCls: "",
      checkoutActCls: "",
      catering_date: "",
      catering_time: "",
      breaktime_enable: "No",
      breaktime_count: 1,
      breaktime_list: [],
      break_timeHtml: "",
      cartItems: [],
      cartDetails: [],
      cartTotalItmCount: 0,
      cateVenueFlag: "",
      catePrimaryId: "",
      category_id: "",
      category_name: "",
      catering_outlet_id: "",
      catering_hall_id: "",
      cart_outlet_id: "",
      cart_outlet_txt: "",
      cart_outlet_unitNum: "",
      cart_hall_id: "",
      cart_hall_txt: "",
      trigger_venue_submit: "No",
      trigger_checkout_submit: "No",
      active_tabflag: 1,
      add_new_item: "No",
      overAllCateringCart: [],
      cateringCartItems: [],
      cateringCartDetails: [],
      cateringCartTotalItmCount: 0,
      trigger_venue_type: "No",
      venue_type_Initail: "",
      callsurchareg: "No",
    };

    var avilablityIdTxt = cookie.load("defaultAvilablityId");
    if (avilablityIdTxt === deliveryId || avilablityIdTxt === pickupId) {
      this.props.history.push("/products");
      return false;
    }

    if (avilablityIdTxt === reservationId) {
      this.props.history.push("/reservation");
      return false;
    }

    var venueTypeInitail =
      typeof cookie.load("venueTypeInitail") !== "undefined"
        ? cookie.load("venueTypeInitail")
        : "";
    if (venueTypeInitail === "") {
      cookie.save("venueTypeInitail", "indoor");
      venueTypeInitail = "indoor";
    }
    this.state["venue_type_Initail"] = venueTypeInitail;

    cookie.save("cartTotalItems", 0);
    cookie.remove("dateTimeTrigger");
    this.props.getCateringCartDetail();
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.cateringCartItems !== nextProps.cateringCartItems) {
      var cartDetailsMain = nextProps.cateringCartDetails;
      if (Object.keys(cartDetailsMain).length > 0) {
        cookie.save("cartTotalItems", nextProps.cateringCartTotalItmCount);
        if (cartDetailsMain.cart_breaktime_enable === "Yes") {
          var cartBrkLst =
            cartDetailsMain.cart_breaktime_list !== "" &&
            cartDetailsMain.cart_breaktime_list !== null
              ? JSON.parse(cartDetailsMain.cart_breaktime_list)
              : [];
          this.setState({
            breaktime_enable: cartDetailsMain.cart_breaktime_enable,
            breaktime_count: cartDetailsMain.cart_breaktime_count,
            breaktime_list: cartBrkLst,
          });
        }
      }

      this.setState(
        {
          cartTotalItmCount: nextProps.cateringCartTotalItmCount,
          cartDetails: cartDetailsMain,
          cartItems: nextProps.cateringCartItems,
          overAllCateringCart: nextProps.overAllCateringCart,
          cateringCartTotalItmCount: nextProps.cateringCartTotalItmCount,
          cateringCartDetails: cartDetailsMain,
          cateringCartItems: nextProps.cateringCartItems,
        },
        function () {
          this.getSingleCartProduct();
        }.bind(this)
      );
    }

    setTimeout(function () {
      if ($(".input-focus").length > 0) {
        $(".input-focus").focus(function () {
          $(this).parents(".focus-out").addClass("focused");
        });
        $(".input-focus").blur(function () {
          var inputValue = $(this).val();
          if (inputValue == "") {
            $(this).removeClass("filled");
            $(this).parents(".focus-out").removeClass("focused");
          } else {
            $(this).addClass("filled");
          }
        });
      }
    }, 400);
  }

  getSingleCartProduct() {
    var cartItems = this.state.cartItems;
    if (Object.keys(cartItems).length > 0) {
      var crtProductId = cartItems[0].cart_item_product_id;
      if (crtProductId !== "") {
        axios
          .get(
            apiUrlV2 +
              "catering/cateringproducts_detail?app_id=" +
              appId +
              "&product_id=" +
              crtProductId +
              "&availability=" +
              cateringId +
              "&status=A&"
          )
          .then((res) => {
            var proResultset = Array();
            if (res.data.status === "success") {
              if (Object.keys(res.data.result_set).length > 0) {
                proResultset = res.data.result_set[0];
                cookie.save(
                  "catering_categoryPryId",
                  proResultset.pro_cate_primary_id
                );
                cookie.save(
                  "catering_categoryId",
                  proResultset.product_category_id
                );
                cookie.save(
                  "catering_categoryName",
                  stripslashes(proResultset.catgory_name)
                );
                this.setState({
                  catePrimaryId: proResultset.pro_cate_primary_id,
                  category_id: proResultset.product_category_id,
                  category_name: stripslashes(proResultset.catgory_name),
                });
              }
            }
          });
      }

      var cartDetailsArr = this.state.cartDetails;
      var outletId = cartDetailsArr.outlet_id;
      var hallId = cartDetailsArr.cart_hall_id;
      var hallChrg = cartDetailsArr.cart_hall_charges;
      var eventtypeTxt = cartDetailsArr.cart_venue_type;
      var venueTypeInitail = cookie.load("venueTypeInitail");
      var eventtype = "";
      if (outletId !== "" && eventtypeTxt === "hall") {
        axios
          .get(
            apiUrlV2 +
              "catering/cateringHalls?app_id=" +
              appId +
              "&hall_id=" +
              hallId
          )
          .then((res) => {
            var seletedHallId = "";
            if (res.data.status === "ok") {
              var resultData = res.data.result_set;

              if (resultData.length == 1) {
                seletedHallId =
                  resultData[0].outlet_id +
                  "~" +
                  stripslashes(resultData[0].outlet_name) +
                  "~" +
                  resultData[0].catering_hall_setup_id +
                  "~" +
                  stripslashes(resultData[0].catering_hall_title) +
                  "~" +
                  resultData[0].catering_hall_postal_code +
                  "~" +
                  stripslashes(resultData[0].catering_hall_address) +
                  "~" +
                  hallChrg +
                  "~" +
                  resultData[0].catering_hall_pax;
              }
            }

            this.setState({
              cateVenueFlag: "hall",
              venue_type_Initail: "indoor",
              cart_hall_id: hallId,
              cart_hall_txt: seletedHallId,
              catering_outlet_id: outletId,
              catering_hall_id: hallId,
            });
          });
        venueTypeInitail = "indoor";
        eventtype = "hall";
      } else if (outletId !== "") {
        axios
          .get(
            apiUrlV2 +
              "outlets/getAllOutles?app_id=" +
              appId +
              "&availability_id=" +
              cateringId +
              "&outlet_id=" +
              outletId
          )
          .then((res) => {
            var seletedOutletId = "";
            var unitNum = "";
            if (res.data.status === "ok") {
              var resultData = res.data.result_set;

              if (resultData.length == 1) {
                if (
                  resultData[0].outlet_unit_number1 !== "" ||
                  resultData[0].outlet_unit_number2 !== ""
                ) {
                  unitNum =
                    resultData[0].outlet_unit_number2 !== ""
                      ? " #" +
                        resultData[0].outlet_unit_number1 +
                        "-" +
                        resultData[0].outlet_unit_number2
                      : " #" + resultData[0].outlet_unit_number1;
                }
                seletedOutletId =
                  resultData[0].oa_outlet_id +
                  "~" +
                  resultData[0].outlet_postal_code +
                  "~" +
                  stripslashes(resultData[0].outlet_name) +
                  "~" +
                  stripslashes(resultData[0].outlet_address_line1);
              }
            }

            this.setState({
              cateVenueFlag: "venue",
              venue_type_Initail: "outdoor",
              cart_outlet_id: outletId,
              cart_outlet_txt: seletedOutletId,
              cart_outlet_unitNum: unitNum,
              catering_outlet_id: outletId,
            });
          });
        venueTypeInitail = "outdoor";
        eventtype = "venue";
      }

      cookie.save("venueTypeInitail", venueTypeInitail);
      cookie.save("catering_eventType", eventtype);
    }
  }

  eventTypePopupMain() {
    $.magnificPopup.open({
      items: {
        src: "#venue-popup",
      },
      type: "inline",
      showCloseBtn: false,
      closeOnBgClick: false,
    });
  }

  changeEvtType(eventtype) {
    if (this.state.venue_type_Initail !== eventtype) {
      var totalItems = cookie.load("cartTotalItems");
      if (parseInt(totalItems) > 0) {
        $.magnificPopup.open({
          items: {
            src: "#clearpopupid",
          },
          type: "inline",
        });
      } else {
        this.resetEventType(eventtype, "no");
      }
    } else {
      $.magnificPopup.close();
    }
  }

  resetEventType(eventtype, trigger) {
    var tempVl = "";
    cookie.save("catering_eventType", tempVl);
    cookie.save("catering_categoryPryId", tempVl);
    cookie.save("catering_categoryId", tempVl);
    cookie.save("catering_categoryName", tempVl);

    cookie.save("venueTypeInitail", eventtype);

    this.setState(
      {
        venue_type_Initail: eventtype,
        cateVenueFlag: tempVl,
        catePrimaryId: tempVl,
        category_id: tempVl,
        category_name: tempVl,
        venueTypeChanged: "No",
      },
      function () {
        $.magnificPopup.close();
        if (trigger === "yes") {
          window.location.reload();
        }
      }.bind(this)
    );
  }

  cartDestoryAndRstEvntFun() {
    var eventtype =
      this.state.venue_type_Initail === "indoor" ? "outdoor" : "indoor";

    var postCrtObject = {};
    postCrtObject = {
      app_id: appId,
    };
    if (typeof cookie.load("UserId") === "undefined") {
      postCrtObject["reference_id"] = getReferenceID();
    } else {
      postCrtObject["customer_id"] = cookie.load("UserId");
    }

    axios
      .post(apiUrlV2 + "cateringcart/destroy", qs.stringify(postCrtObject))
      .then((res) => {
        if (res.data.status === "ok") {
          cookie.save("fromVenueFlg", "Yes");
          showCustomAlert("success", "Nice! Your cart is empty");
          var cartDetails = Array();
          showCartItemCount(cartDetails);
          this.resetEventType(eventtype, "yes");
        } else {
          $.magnificPopup.close();
          showCustomAlert("error", "Sorry! Products can`t update your cart");
        }
      })
      .catch(function (error) {
        console.log(error);
        showCustomAlert("error", "something went wrong");
        $.magnificPopup.close();
      });
  }

  setActiveTab = (tabid) => {
    var userId = cookie.load("UserId");
    var catgPryId = cookie.load("catering_categoryPryId");
    var eventType = cookie.load("catering_eventType");
    var OutletId = cookie.load("cateringOutletId");
    var HallId = cookie.load("cateringHallId");
    var totalItems = cookie.load("cartTotalItems");

    var sateCatPrmId = this.state.catePrimaryId;
    sateCatPrmId = sateCatPrmId === "" ? catgPryId : sateCatPrmId;

    var categoryAct = "";
    var venueDetAct = "";
    var packageAct = "";
    var checkoutAct = "";

    if (tabid === 1) {
      categoryAct = "active";
    } else if (tabid === 2) {
      if (
        typeof catgPryId !== "undefined" &&
        catgPryId !== "" &&
        sateCatPrmId !== ""
      ) {
        categoryAct = "active";
        venueDetAct = "active";
      } else {
        tabid = 1;
        categoryAct = "active";
      }
    } else if (tabid === 3) {
      if (
        typeof catgPryId !== "undefined" &&
        catgPryId !== "" &&
        sateCatPrmId !== "" &&
        ((eventType === "venue" &&
          typeof OutletId !== "undefined" &&
          OutletId !== "") ||
          (eventType === "hall" &&
            typeof HallId !== "undefined" &&
            HallId !== ""))
      ) {
        if (
          this.state.catering_outlet_id === OutletId &&
          this.state.catering_hall_id === HallId
        ) {
          categoryAct = "active";
          venueDetAct = "active";
          packageAct = "active";
        } else {
          tabid = 2;
          categoryAct = "active";
          venueDetAct = "active";
          this.setState({ trigger_venue_submit: "Yes" });
        }
      } else if (
        typeof catgPryId !== "undefined" &&
        catgPryId !== "" &&
        sateCatPrmId !== ""
      ) {
        tabid = 2;
        categoryAct = "active";
        venueDetAct = "active";
      } else {
        tabid = 1;
        categoryAct = "active";
      }
    } else if (tabid === 4) {
      this.setState({ callsurchareg: "Yes" });
      if (
        typeof catgPryId !== "undefined" &&
        catgPryId !== "" &&
        sateCatPrmId !== "" &&
        ((eventType === "venue" &&
          typeof OutletId !== "undefined" &&
          OutletId !== "") ||
          (eventType === "hall" &&
            typeof HallId !== "undefined" &&
            HallId !== "")) &&
        parseInt(totalItems) > 0
      ) {
        if (typeof userId !== "undefined" && userId !== "") {
          categoryAct = "active";
          venueDetAct = "active";
          packageAct = "active";
          checkoutAct = "active";
        } else {
          tabid = 1;
          categoryAct = "active";
          this.setState({ trigger_checkout_submit: "Yes" });
          cookie.save("fromCkeckOutVld", "Yes");
        }
      } else if (
        typeof catgPryId !== "undefined" &&
        catgPryId !== "" &&
        sateCatPrmId !== "" &&
        ((eventType === "venue" &&
          typeof OutletId !== "undefined" &&
          OutletId !== "") ||
          (eventType === "hall" &&
            typeof HallId !== "undefined" &&
            HallId !== ""))
      ) {
        if (
          this.state.catering_outlet_id === OutletId &&
          this.state.catering_hall_id === HallId
        ) {
          tabid = 3;
          categoryAct = "active";
          venueDetAct = "active";
          packageAct = "active";
          if (parseInt(totalItems) === 0) {
            showCustomAlert("error", "Sorry! Your cart is empty.");
          }
        } else {
          tabid = 2;
          categoryAct = "active";
          venueDetAct = "active";
          this.setState({ trigger_venue_submit: "Yes" });
        }
      } else if (
        typeof catgPryId !== "undefined" &&
        catgPryId !== "" &&
        sateCatPrmId !== ""
      ) {
        tabid = 2;
        categoryAct = "active";
        venueDetAct = "active";
      } else {
        tabid = 1;
        categoryAct = "active";
      }
    }

    this.setState({
      active_tabflag: tabid,
      categoryActCls: categoryAct,
      venueDetActCls: venueDetAct,
      packageActCls: packageAct,
      checkoutActCls: checkoutAct,
    });
  };

  sateValChange = (field, value) => {
    if (field === "catering_date") {
      this.setState({ catering_date: value });
    } else if (field === "catering_time") {
      this.setState({ catering_time: value });
    } else if (field === "breaktime_enable") {
      this.setState({ breaktime_enable: value });
    } else if (field === "breaktime_count") {
      this.setState({ breaktime_count: value });
    } else if (field === "breaktime_list") {
      this.setState({ breaktime_list: value });
    } else if (field === "break_timeHtml") {
      this.setState({ break_timeHtml: value });
    } else if (field === "outlet_id") {
      this.setState({ catering_outlet_id: value });
    } else if (field === "hall_id") {
      this.setState({ catering_hall_id: value });
    } else if (field === "venue_submit") {
      this.setState({ trigger_venue_submit: value });
    } else if (field === "catePrimaryId") {
      this.setState({ catePrimaryId: value });
    } else if (field === "cateVenueFlag") {
      this.setState({ cateVenueFlag: value });
    } else if (field === "venueTypeChanged") {
      this.setState({ trigger_venue_type: value });
    } else if (field === "category_id") {
      this.setState({ category_id: value });
    } else if (field === "checkout_submit") {
      this.setState({ trigger_checkout_submit: value });
    } else if (field === "category_name") {
      this.setState({ category_name: value });
    } else if (field === "active_tabflag") {
      this.setState({ active_tabflag: value });
    } else if (field === "add_new_item") {
      this.setState({ add_new_item: value });
      /*this.props.getCateringCartDetail();*/
    } else if (field === "callsurchareg") {
      this.setState({ callsurchareg: value });
    }
  };

  changeCatgryDetFun = (valArr) => {
    cookie.save("catering_categoryPryId", valArr["categoryPryId"]);
    cookie.save("catering_categoryId", valArr["category_id"]);
    cookie.save("catering_categoryName", valArr["category_name"]);

    this.setState({
      catePrimaryId: valArr["categoryPryId"],
      category_id: valArr["category_id"],
      category_name: valArr["category_name"],
    });
  };

  showsActiveDiv = (valtxt) => {
    var tabflag = this.state.active_tabflag;
    return parseInt(valtxt) === parseInt(tabflag) ? "block" : "none";
  };

  showsActiveHdr = (valtxt) => {
    var tabflag = this.state.active_tabflag;
    return parseInt(valtxt) === parseInt(tabflag) ? "active" : "";
  };

  componentDidMount() {
    var venueTypeInitail =
      typeof cookie.load("venueTypeInitail") !== "undefined"
        ? cookie.load("venueTypeInitail")
        : "";
    if (venueTypeInitail === "") {
      this.eventTypePopupMain();
    }
  }

  closeEvtType() {
    $.magnificPopup.close();
    if (this.state.venue_type_Initail === "") {
      cookie.save("orderPopuptrigger", "Yes");
      this.props.history.push("/");
      return false;
    }
  }

  render() {
    var settingsCartering = {
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1191,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: false,
          },
        },
        {
          breakpoint: 520,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
          },
        },
      ],
    };

    return (
      <div>
        <Header sateValChange={this.sateValChange} />
        <div className="container common-top-div catering-main-div">
          {/* Header start */}

          {/* Header End */}

          {/* innersection_wrap - start */}
          <div className="innersection_wrap">
            <div className="mainacc_toptext">
              <h2>Banquet Services</h2>
              {/*<p>text box description will be here. sample text line here text box description will be here. sample text line here</p>*/}
            </div>

            {/* tab sec top div - start */}
            <div className="tab_sec order_nav">
              <ul
                className="mobile-catering-slider catering-top-navdiv"
                style={{ display: "none" }}
              >
                <Slider {...settingsCartering}>
                  <li
                    className={
                      this.state.commonTabCls +
                      " categorylistLi " +
                      this.state.categoryActCls
                    }
                    onClick={this.setActiveTab.bind(this, 1)}
                  >
                    <a href="javascript:void(0)">
                      <span className="nos">1</span>
                      <span>PACKAGES</span>
                    </a>
                  </li>
                  <li
                    className={
                      this.state.commonTabCls +
                      " venueDetLi " +
                      this.state.venueDetActCls
                    }
                    onClick={this.setActiveTab.bind(this, 2)}
                  >
                    <a href="javascript:void(0)">
                      <span className="nos">2</span>
                      <span>VENUE DETAILS</span>
                    </a>
                  </li>
                  <li
                    className={
                      this.state.commonTabCls +
                      " packageLi " +
                      this.state.packageActCls
                    }
                    onClick={this.setActiveTab.bind(this, 3)}
                  >
                    <a href="javascript:void(0)">
                      <span className="nos">3</span>
                      <span>SELECT MENU</span>
                    </a>
                  </li>
                  <li
                    className={
                      this.state.commonTabCls +
                      " checkoutLi " +
                      this.state.checkoutActCls
                    }
                    onClick={this.setActiveTab.bind(this, 4)}
                  >
                    <a href="javascript:void(0)">
                      <span className="nos">4</span>
                      <span>CHECKOUT</span>
                    </a>
                  </li>
                </Slider>
              </ul>

              <ul className="nav nav-tabs catering-top-navdiv">
                <li
                  className={
                    this.state.commonTabCls +
                    " categorylistLi " +
                    this.state.categoryActCls
                  }
                  onClick={this.setActiveTab.bind(this, 1)}
                >
                  <a href="javascript:void(0)">
                    <span className="nos">1</span>
                    <span>PACKAGES</span>
                  </a>
                </li>
                <li
                  className={
                    this.state.commonTabCls +
                    " venueDetLi " +
                    this.state.venueDetActCls
                  }
                  onClick={this.setActiveTab.bind(this, 2)}
                >
                  <a href="javascript:void(0)">
                    <span className="nos">2</span>
                    <span>VENUE DETAILS</span>
                  </a>
                </li>
                <li
                  className={
                    this.state.commonTabCls +
                    " packageLi " +
                    this.state.packageActCls
                  }
                  onClick={this.setActiveTab.bind(this, 3)}
                >
                  <a href="javascript:void(0)">
                    <span className="nos">3</span>
                    <span>SELECT MENU</span>
                  </a>
                </li>
                <li
                  className={
                    this.state.commonTabCls +
                    " checkoutLi " +
                    this.state.checkoutActCls
                  }
                  onClick={this.setActiveTab.bind(this, 4)}
                >
                  <a href="javascript:void(0)">
                    <span className="nos">4</span>
                    <span>CHECKOUT</span>
                  </a>
                </li>
              </ul>

              {/* tab content main div - start */}
              <div className="tab-content">
                {/* Tab1 Category list */}
                <div
                  className="tab-pane-main categorylistTopDiv"
                  style={{ display: this.showsActiveDiv(1) }}
                >
                  <Categorylist
                    categoryState={this.state}
                    setActiveTab={this.setActiveTab}
                    sateValChangefun={this.sateValChange}
                    showsActiveDiv={this.showsActiveDiv}
                    showsActiveHdr={this.showsActiveHdr}
                    cateringCartDetails={this.props.cateringCartDetails}
                  />
                </div>

                {/* Tab2 Venue Details */}
                <div
                  className="tab-pane-main venueTopDiv"
                  style={{ display: this.showsActiveDiv(2) }}
                >
                  <Venuedetails
                    {...this.props}
                    categoryState={this.state}
                    sateValChangefun={this.sateValChange}
                    setActiveTab={this.setActiveTab}
                    showsActiveDiv={this.showsActiveDiv}
                    showsActiveHdr={this.showsActiveHdr}
                    changeCatgryDet={this.changeCatgryDetFun}
                  />
                </div>

                {/* Tab3 Package Details */}
                <div
                  className="tab-pane-main packageTopDiv upkg_tabsec"
                  style={{ display: this.showsActiveDiv(3) }}
                >
                  <Packagedetails
                    categoryState={this.state}
                    sateValChangefun={this.sateValChange}
                    setActiveTab={this.setActiveTab}
                    showsActiveDiv={this.showsActiveDiv}
                    showsActiveHdr={this.showsActiveHdr}
                    changeCatgryDet={this.changeCatgryDetFun}
                  />
                </div>

                {/* Tab4 Checkout Details */}
                <div
                  className="tab-pane-main checkoutTopDiv"
                  style={{ display: this.showsActiveDiv(4) }}
                >
                  <Checkout
                    categoryState={this.state}
                    sateValChangefun={this.sateValChange}
                    setActiveTab={this.setActiveTab}
                    showsActiveDiv={this.showsActiveDiv}
                    showsActiveHdr={this.showsActiveHdr}
                  />
                </div>
              </div>
              {/* tab content main div - End */}
            </div>
            {/* tab sec top div - end */}
          </div>
          {/* innersection_wrap - end */}
        </div>
        {/* Footer section */}

        {/* venue type popup - start */}
        <div
          id="venue-popup"
          className="white-popup mfp-hide popup_sec venue_popup"
        >
          <div className="order-body">
            <button
              title="Close (Esc)"
              onClick={this.closeEvtType.bind(this)}
              type="button"
              className="mfp-custom-close close-icon-but"
            >
              ×
            </button>

            <h2>Event Type</h2>
            <p>Select your event type</p>
            <div className="venue_type_row">
              <div className="venue_type_col">
                <ul className="venue_type_item">
                  <li
                    className={
                      this.state.venue_type_Initail === "outdoor"
                        ? "venuetype-active"
                        : ""
                    }
                  >
                    <a
                      href="javascript:;"
                      onClick={this.changeEvtType.bind(this, "outdoor")}
                    >
                      Outdoor Catering
                    </a>
                  </li>

                  <li
                    className={
                      this.state.venue_type_Initail === "indoor"
                        ? "venuetype-active"
                        : ""
                    }
                  >
                    <a
                      href="javascript:;"
                      onClick={this.changeEvtType.bind(this, "indoor")}
                    >
                      Indoor Catering
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* venue type popup - end */}

        <div
          className="white-popup mfp-hide popup_sec"
          id="clearpopupid"
          style={{ maxWidth: 500 }}
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_header" style={{ textAlign: "center" }}>
                  Wait a second !
                </div>
                <div className="alert_body">
                  <img src={CantDlyImg} />
                  <p>By switching you are about to clear your cart.</p>
                  <p>Do you wish to proceed ?</p>
                  <div className="alt_btns">
                    <a
                      href="javascript:void(0);"
                      className="button popup-modal-dismiss"
                    >
                      Cancel
                    </a>
                    <a
                      href="javascript:void(0);"
                      className="button btn_yellow"
                      onClick={this.cartDestoryAndRstEvntFun.bind(this)}
                    >
                      Yes Proceed
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var tempArr = Array();
  var menu_slug = "";
  var menu_type = "";
  var navigateRst = Array();
  var navigateCmn = Array();
  if (Object.keys(state.product).length > 0) {
    var tempArr = !("menuNavigation" in state.product[0])
      ? Array()
      : state.product[0].menuNavigation;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        navigateRst = tempArr[0].result_set;
        navigateCmn = tempArr[0].common;
        menu_slug = tempArr[0].result_set[0].pro_cate_slug;
        menu_type =
          tempArr[0].result_set[0].menu_type == "main"
            ? "category"
            : "subcategory";
      }
    }
  }

  var overAllCateringCart = Array();
  var cateringCartDetails = Array();
  var cateringCartItems = Array();
  var cateringCartTotalItmCount = 0;
  if (Object.keys(state.cateringcartlistdetail).length > 0) {
    var resultSetArr = !("result_set" in state.cateringcartlistdetail[0])
      ? Array()
      : state.cateringcartlistdetail[0].result_set;
    if (
      state.cateringcartlistdetail[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      overAllCateringCart = resultSetArr;
      cateringCartDetails = resultSetArr.cart_details;
      cateringCartItems = resultSetArr.cart_items;
      cateringCartTotalItmCount = resultSetArr.cart_details.cart_total_items;
    }
  }

  return {
    navigateMenu: navigateRst,
    navigateCommon: navigateCmn,
    selectedNavigation: menu_slug,
    selectedSlugType: menu_type,
    overAllCateringCart: overAllCateringCart,
    cateringCartDetails: cateringCartDetails,
    cateringCartItems: cateringCartItems,
    cateringCartTotalItmCount: cateringCartTotalItmCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMenuNavigationList: () => {
      dispatch({ type: GET_MENU_NAVIGATION });
    },
    getCateringCartDetail: () => {
      dispatch({ type: GET_CATERINGCART_DETAIL });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(Catering);
