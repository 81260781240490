/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import { connect } from "react-redux";
import update from "immutability-helper";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import MenuNavigation from "./MenuNavigation";
import ProductList from "./ProductList";
import { cateringId, reservationId } from "../Helpers/Config";
import { GET_MENU_NAVIGATION } from "../../actions";

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigateMenu: [],
      sub_navigateMenu: [],
      selectedCategoryName: "",
      selectedNavigation: "",
      selectedMenucatid: "",
      selectedSlugType: "",
      catNavIndex: 0,
      catslugType: "",
      catslugValue: "",
      cartTriggerFlg: "No",
    };

    var avilablityIdTxt = cookie.load("defaultAvilablityId");
    if (avilablityIdTxt === cateringId) {
      this.props.history.push("/catering");
      return false;
    }

    if (avilablityIdTxt === reservationId) {
      this.props.history.push("/reservation");
      return false;
    }

    var orderOutletId =
      cookie.load("orderOutletId") != "" &&
      cookie.load("orderOutletId") != undefined
        ? cookie.load("orderOutletId")
        : "";
    var avilablityId =
      cookie.load("defaultAvilablityId") != "" &&
      cookie.load("defaultAvilablityId") != undefined
        ? cookie.load("defaultAvilablityId")
        : "";

    if (orderOutletId === "" || avilablityId === "") {
      cookie.save("orderPopuptrigger", "Yes", {
        path: "/",
      });
      this.props.history.push("/");
      return;
    }

  }

  componentDidMount() {
    var categorySlug = "";
    var menu_slug = "";
    var menu_name = "";
    var menu_type = "";
    if (this.props.match.params.slugType !== "") {
      categorySlug = this.props.match.params.slugValue;
      menu_type = this.props.match.params.slugType;
    }
    // console.log(this.props, "this.propsthis.props");
    this.props.getMenuNavigationList(
      categorySlug,
      menu_slug,
      menu_name,
      menu_type
    );

    /*SCROLL FIXED PRODUCT PAGE*/
    $(window).scroll(function() {
      var productlist_height = $(".productlist-main-div").offset();
      if (productlist_height !== undefined) {
        var mycustomscroll = $(".productlist-main-div").offset().top - 122,
          wind_toptxt = $(window).scrollTop();

        if (wind_toptxt > mycustomscroll) {
          $(".bakery_row").addClass("catogry_row_fixed");
        } else {
          $(".bakery_row").removeClass("catogry_row_fixed");
        }
      }
    });
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }

    if (field === "subcategory") {
      this.setState({ selectedsubNavigation: value });
    }
  };

  productFlageChange = (field, value) => {
    this.setState(
      update(this.state, { productflage: { [field]: { $set: value } } })
    );
  };

  render() {
    return (
      <div>
        <div className="common-top-div productpage-main-div">
          {/* Header start */}
          <Header
            cartTriggerFlg={this.state.cartTriggerFlg}
            sateValChange={this.sateValChange}
            showCatryName={this.state.selectedCategoryName}
          />
          {/* Header End */}
          <div className="container">
            <MenuNavigation
              {...this.props}
              productState={this.state}
              sateValChange={this.sateValChange}
            />

            <ProductList
              {...this.props}
              productState={this.state}
              sateValChange={this.sateValChange}
            />
          </div>
        </div>
        {/* Footer section */}
        <Footer />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var tempArr = Array();
  var menu_slug = "";
  var menu_cat_id = "";
  var menu_type = "";
  var navigateRst = Array();
  var navigateCmn = Array();
  var selectedCatry = "";

  let sub_navigateRst = Array();
  let sub_navigateCmn = Array();
  let sub_selectedCatry = "";

  if (Object.keys(state.product).length > 0) {
    var tempArr = !("menuNavigation" in state.product[0])
      ? Array()
      : state.product[0].menuNavigation;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        navigateRst = tempArr[0].result_set;
        navigateCmn = tempArr[0].common;
        selectedCatry = tempArr[0].result_set[0].category_name;
        menu_slug = tempArr[0].result_set[0].pro_cate_slug;
        menu_cat_id = tempArr[0].result_set[0].menu_category_id;
        menu_type =
          tempArr[0].result_set[0].menu_type == "main"
            ? "category"
            : "subcategory";
      }
      // console.log(tempArr, "tempArrtempArr");
      if (tempArr[0].status === "ok") {
        sub_navigateRst = tempArr[0].result_set;
        sub_navigateCmn = tempArr[0].common;
        sub_selectedCatry = tempArr[0].result_set[0].subcategory_name;
      }
    }
  }

  return {
    navigateMenu: navigateRst,
    navigateCommon: navigateCmn,
    selectedCatry: selectedCatry,
    selectedNavigation: menu_slug,
    selectedMenucatid: menu_cat_id,
    selectedSlugType: menu_type,
    sub_navigateMenu: sub_navigateRst,
    sub_selectedCatry: sub_selectedCatry,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMenuNavigationList: (
      menu_category_id,
      menu_slug,
      menu_name,
      menu_type
    ) => {
      dispatch({
        type: GET_MENU_NAVIGATION,
        menu_category_id,
        menu_slug,
        menu_name,
        menu_type,
      });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(Products);
