/* eslint-disable */
import React, { Component } from 'react';

import { withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import { fbAppId, baseUrl, appId, apiUrl, cateringId} from "../Helpers/Config";

import { validated } from 'react-custom-validation';
import validator from 'validator';

const isEmpty = (value) =>
value === '' ? 'This field is required.' : null
const isEmail = (email) =>
validator.isEmail(email) ? null : 'This is not a valid email.'


function validationConfigQutrequest(props) {

    const { firstname, lastname, email, mobile } = props.fields

    return {
        fields: ['firstname', 'lastname', 'email', 'mobile'],

        validations: {
            firstname: [
                [isEmpty, firstname]
            ],
            lastname: [
                [isEmpty, lastname]
            ],
            email: [
               [isEmail, email],
                /* [isUnique, email]*/
            ],
            mobile: [
                [isEmpty, mobile],
                [isMobile, mobile],
               /* [isPhonenumerExist, mobile]*/
            ],
        }
    }
}


const phonenumberPattern = /^[0-9]{6,14}$/;
const isMobile = (mobile) =>
    mobile.match(phonenumberPattern) ? null : 'please enter valid Mobile Number.'


const isUnique = (email) => {

    var qs = require('qs');
    var postObject = {
        "app_id": appId,
        "type": "web",
        "customer_email": email
    };

    axios.post(apiUrl + "customer/email_exist", qs.stringify(postObject)).then(response => {
        if (response.data.status === "ok") {
            document.getElementById("spn-email-error").innerHTML = '';
        } else {
            document.getElementById("spn-email-error").innerHTML = '<span class="error">Email already exists</span>';
        }
    });

}

const isPhonenumerExist = (mobile) => {
    var qs = require('qs');
    var postObject = {
        "app_id": appId,
        "type": "web",
        "customer_phone": mobile
    };
    axios.post(apiUrl + "customer/phoneno_exist", qs.stringify(postObject)).then(response => {
        if (response.data.status === "ok") {
            document.getElementById("spn-mobile-error").innerHTML = '';
        } else {
            document.getElementById("spn-mobile-error").innerHTML = '<span class="error">Mobile number already exists</span>';
        }
    });

}


class Qutrequest extends Component {

    constructor(props) {
        super(props);

    }

    closepopup(path) {

		const{history} = this.props;

       history.push(path);
        $.magnificPopup.close();
    }

    render() {

        const { fields, onChange, onValid, onInvalid, $field, $validation } = this.props
        const spanStyle = {
            clear: 'both'
        };
        let errMsgFirstName, errMsgLastName, errMsgEmail, errMsgPassword, errMsgrePassword, errMsgMobile, errMsgTerms;

        if ($validation.firstname.error.reason !== undefined) {
            errMsgFirstName = ($validation.firstname.show && <span className="error">{$validation.firstname.error.reason}</span>)
        }
        if ($validation.lastname.error.reason !== undefined) {
            errMsgLastName = ($validation.lastname.show && <span className="error">{$validation.lastname.error.reason}</span>)
        }
        if ($validation.email.error.reason !== undefined) {
            errMsgEmail = ($validation.email.show && <span className="error">{$validation.email.error.reason}</span>)
        }
        if ($validation.mobile.error.reason !== undefined) {
            errMsgMobile = ($validation.mobile.show && <span className="error">{$validation.mobile.error.reason}</span>)
        }


        return (
            <div className="popup-body">
                <h4>Your Contact Details</h4>
                <div className="form-group">
                    <div className="focus-out">
                        <label>First Name</label>
                        <input type="text" className="form-control input-focus" value={fields.firstname} {...$field('firstname', (e) => onChange('firstname', e.target.value)) } />
                        {errMsgFirstName}
                    </div>

                </div>
                <div className="form-group">
                    <div className="focus-out">
                        <label>Last Name</label>
                        <input type="text" className="form-control input-focus" value={fields.lastname} {...$field('lastname', (e) => onChange('lastname', e.target.value)) } />
                        {errMsgLastName}
                    </div>
                </div>
                <div className="form-group">
                    <div className="focus-out">
                        <label>Mobile Number</label>
                        <input type="tel" className="form-control input-focus" pattern="\d*" value={fields.mobile} {...$field('mobile', (e) => onChange('mobile', e.target.value)) } />
                        <div id="spn-mobile-error">{errMsgMobile}</div>


                    </div>
                </div>
                <div className="form-group">
                    <div className="focus-out">
                        <label>Email Address</label>
                        <input type="text" className="form-control input-focus" value={fields.email} {...$field('email', (e) => onChange('email', e.target.value)) } />
                        <div id="spn-email-error">{errMsgEmail}</div>
                    </div>
                </div>
                
                <div className="form-group">
                    <div className="login_pop_sub qtn_div_cls">

                        <button type="button" className="btn btn_black btn_minwid signup_submit" onClick={(e) => { e.preventDefault(); this.props.$submit(onValid, onInvalid); }}>Download Quotation</button>
                    </div>
                </div>

            </div>
        );
    }
}
Qutrequest = validated(validationConfigQutrequest)(Qutrequest)

export default withRouter(Qutrequest);
