/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import { GET_BANNER_LIST } from "../../../actions";
import { appId } from "../../Helpers/Config";

import Parser from "html-react-parser";

class HomeBanner extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.props.getBannerList();
  }
  render() {
    let bannerArr = this.props.banner;
    let bannerlist = [];
    let bannerimagesource = "";
    let bannerimagesources3 =
      "https://s3-ap-southeast-1.amazonaws.com/ninjaos/georges/banners/";
    if (Object.keys(bannerArr).length > 0) {
      if (bannerArr[0].status === "ok") {
        bannerlist = bannerArr[0].result_set;
        bannerimagesource = bannerArr[0].common.image_source;
      }
    }

    var settingsGallery = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      arrows: false,
      dots: appId !== "9C56E15B-CEE2-4A23-B333-09C458896BC3" ? true : false,
    };

    return (
      <div className="home-banner-universal">
        {Object.keys(bannerlist).length > 0 ? (
          <Slider {...settingsGallery}>
            {bannerlist.map((banner, index) => {
              return (
                <div key={index}>
                  <img
                    src={
                      (banner.resources_location == "S3"
                        ? bannerimagesources3
                        : bannerimagesource) + banner.banner_image
                    }
                    alt="Banner"
                  />
                  {banner.banner_description !== "" &&
                    banner.banner_description !== null && (
                      <div className="homebanner-content">
                        <div className="banner-text">
                          {Parser(banner.banner_description)}
                        </div>
                      </div>
                    )}
                </div>
              );
            })}
          </Slider>
        ) : (
          <div className="loader-main-cls">
            <div className="banner loader-sub-div"></div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    banner: state.banner,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBannerList: () => {
      dispatch({ type: GET_BANNER_LIST });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(HomeBanner);
