/* Live */

export const appId = "FCACF7F4-AF83-4EFC-9445-83C270FD1AC2";

export const apiUrl = "https://ccpl.ninjaos.com/api/";
export const apiUrlV2 = "https://ccpl.ninjaos.com/apiv2/";
export const apiUrlPro = "https://ccpl.ninjaos.com/ninjapro/";
export const apiUrlNotify = "https://ccpl.ninjaos.com/Pushorder/";
export const baseUrl = "https://www.georges.com.sg/";
//export const baseUrl = "https://georges.promobuddy.asia/";
//export const baseUrl = "http://localhost:3001/";
export const timThumpUrl = "https://ccpl.ninjaos.com/timthumb.php?src=";
export const blogImageUrl = "https://ccpl.ninjaos.com/media/dev_team/blog/";
export const mediaUrl = "https://ccpl.ninjaos.com/media/";
export const stripeImage = "";
export const webUrl = "https://ccpl.ninjaos.com";

/* 
export const apiUrl       = "https://ccpl.promobuddy.asia/api/";
export const apiUrlV2     = "https://ccpl.promobuddy.asia/apiv2/";
export const apiUrlPro    = "https://ccpl.promobuddy.asia/ninjapro/";
export const apiUrlNotify = "https://ccpl.promobuddy.asia/Pushorder/";
export const baseUrl      = "";
export const timThumpUrl  = "https://ccpl.promobuddy.asia/timthumb.php?src=";
export const blogImageUrl = "https://ccpl.promobuddy.asia/media/dev_team/blog/";
export const stripeImage  = "";
export const webUrl      = "https://ccpl.promobuddy.asia";
*/

export const deliveryId = "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11";
export const pickupId = "718B1A92-5EBB-4F25-B24D-3067606F67F0";
export const cateringId = "EB62AF63-0410-47CC-9464-038E796E28C4";
export const reservationId = "79FA4C7F-75A1-4A95-B7CE-81ECA2575363";
export const dineinId = "EF9FB350-4FD4-4894-9381-3E859AB74019";
export const madbarId = "471745F6-0AEC-4641-9802-6DA1968D5D79";
export const eventsId = "AF70EE93-2B8B-474D-9078-044F259637F3";

export const CountryTxt = "Singapore";
export const productNoImg = "/img/product-noimg.jpg";
export const stripeCompany = "Georges";
export const companyname = "Georges";

export const fbAppId = "4010132705770889";

export const stripeKey = "pk_test_hLfht4KEShHlACDG9mBfrPkv";
export const stripeEmail = "dev@jankosoft.com";
export const stripeReference = "georges";
export const stripeDesc = "My Checkout";
export const stripeCurrency = "SGD";
export const defaultOutletID = "138";

export const getHeader = function() {
  let header = {
    headers: { "X-API-KEY": "D04Ea1c5-b19c-4B58-b2e9-88F5C0456432" },
  };

  /*if(cookie.load('Token')!='' && cookie.load('Token')!=undefined) {
	  header.headers.Auth = cookie.load('Token')
	}*/

  if (
    localStorage.getItem("BeaerToken") !== undefined &&
    localStorage.getItem("BeaerToken") !== "" &&
    localStorage.getItem("BeaerToken") !== null
  ) {
    header.headers.Auth = localStorage.getItem("BeaerToken");
  }

  return header;
};
