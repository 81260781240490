/* eslint-disable */
import { push } from 'react-router-redux';
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_CATERINGCART_DETAIL, SET_CATERINGCART_DETAIL, UPDATE_CATERINGCART_DETAIL, DELETE_CATERINGCART_DETAIL, DELETE_INVCATERINGCART_DETAIL, DESTROY_CATERINGCART_DETAIL } from '../actions';
import { appId, apiUrl, apiUrlV2, cateringId } from "../components/Helpers/Config";
import { getReferenceID } from "../components/Helpers/SettingHelper";
import Axios from 'axios';
import cookie from 'react-cookies';
var qs = require('qs');

export const watchGetCateringCartDetail = function* () {
  yield takeEvery(GET_CATERINGCART_DETAIL, workerGetCateringCartDetail);
}

export const watchUpdateCateringCartDetail = function* () {
  yield takeEvery(UPDATE_CATERINGCART_DETAIL, workerUpdateCateringCartDetail);
}

export const watchDeleteCateringCartDetail = function* () {
  yield takeEvery(DELETE_CATERINGCART_DETAIL, workerDeleteCateringCartDetail);
}

export const watchDeleteInvCateringCartDetail = function* () {
  yield takeEvery(DELETE_INVCATERINGCART_DETAIL, workerDeleteInvCateringCartDetail);
}

export const watchDestroyCateringCartDetail = function* () {
  yield takeEvery(DESTROY_CATERINGCART_DETAIL, workerDestroyCateringCartDetail);
}

function* workerGetCateringCartDetail() {
  try {
	if (typeof cookie.load('UserId') === 'undefined') {
      var customerParam = "&reference_id=" + getReferenceID();
    } else {
      var customerParam = "&customer_id=" + cookie.load('UserId');
    }
	
    const uri = apiUrlV2+'cateringcart/contents?app_id='+appId+customerParam;
    const result = yield call(Axios.get, uri);
	var resultArr = [];
		resultArr.push(result.data);
    yield put({ type: SET_CATERINGCART_DETAIL, value: resultArr });
  } 
  catch {
    console.log('Get Catering Cart Detail Failed');
  }
}

function* workerUpdateCateringCartDetail({productId, cartItemId, cartQty}) {
  try {
	  
	var postObject = {};
    postObject = { 'app_id': appId, 'cart_item_id': cartItemId, 'product_id': productId, 'product_qty': cartQty };

    if (typeof cookie.load('UserId') === 'undefined') {
      postObject['reference_id'] = getReferenceID();
    } else {
      postObject['customer_id'] = cookie.load('UserId');
    }
	
    const uri = apiUrlV2+'cateringcart/update';
    const result = yield call(Axios.post, uri, qs.stringify(postObject));
	yield put({ type: GET_CATERINGCART_DETAIL});
  } 
  catch {
    console.log('Update Catering Cart Detail Failed');
  }
}

function* workerDeleteCateringCartDetail({cartItemId}) {
  try {
	  
	var postObject = {};
    postObject = { 'app_id': appId, 'cart_item_id': cartItemId };

    if (typeof cookie.load('UserId') === 'undefined') {
      postObject['reference_id'] = getReferenceID();
    } else {
      postObject['customer_id'] = cookie.load('UserId');
    }
	
    const uri = apiUrlV2+'cateringcart/delete';
    const result = yield call(Axios.post, uri, qs.stringify(postObject));
	yield put({ type: GET_CATERINGCART_DETAIL});
  } 
  catch {
    console.log('Delete Cart Detail Failed');
  }
}

function* workerDeleteInvCateringCartDetail({cartItemId, cartPryId, type}) {
  try {
	  
	var postObject = {};
    postObject = { 'app_id': appId, 'cart_item_id': cartItemId, 'p_id': cartPryId, 'type': type };

    if (typeof cookie.load('UserId') === 'undefined') {
      postObject['reference_id'] = getReferenceID();
    } else {
      postObject['customer_id'] = cookie.load('UserId');
    }
	
    const uri = apiUrlV2+'cateringcart/delete_individual';
    const result = yield call(Axios.post, uri, qs.stringify(postObject));
	yield put({ type: GET_CATERINGCART_DETAIL});
  } 
  catch {
    console.log('Delete Cart Detail Failed');
  }
}

function* workerDestroyCateringCartDetail() {
  try {
	  
	var postObject = {};
    postObject = { 'app_id': appId };

    if (typeof cookie.load('UserId') === 'undefined') {
      postObject['reference_id'] = getReferenceID();
    } else {
      postObject['customer_id'] = cookie.load('UserId');
    }
	
    const uri = apiUrlV2+'cateringcart/destroy';
    const result = yield call(Axios.post, uri, qs.stringify(postObject));
	yield put({ type: GET_CATERINGCART_DETAIL});
  } 
  catch {
    console.log(' Cart Detail Failed');
  }
}
 
