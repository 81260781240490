/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { stripslashes, addressFormat } from "../Helpers/SettingHelper";

import { appId, apiUrl, webUrl } from "../Helpers/Config";

import Parser from "html-react-parser";
import Axios from "axios";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import innerBanner from "../../common/images/page-banner.jpg";
import ct_icon1 from "../../common/images/address-icon.svg";
import ct_icon2 from "../../common/images/phone-ring.svg";
import ct_icon3 from "../../common/images/gps-icon.svg";

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      outlets: [],
      outletOption: [],

      pageTitle: "",
      pageDesc: "",
      status: "",
      windowHeight: 0,
    };
  }

  callPhoneOptn(phoneTxt) {
  var resultTxt = '';
  if(phoneTxt !== '') {
    if(phoneTxt.indexOf("+65") !== -1) {
      resultTxt = "tel:"+phoneTxt;
    } else if((phoneTxt.indexOf("65") !== -1) && (phoneTxt.length >= 10)) {
      resultTxt = "tel:+"+phoneTxt;
    } else {
      resultTxt = "tel:+65"+phoneTxt;
    }
  } else {
    resultTxt = 'javascript:void(0);';
  }
  return resultTxt;
  }

  componentDidMount() {
    $("html, body").animate({ scrollTop: 0 }, 800);
    Axios.get(apiUrl + "outlets/getAllOutles?app_id=" + appId).then((res) => {
      if (res.data.status === "ok") {
        let outletOption = [];
        let dropDownEle;

        res.data.result_set.forEach((element) => {
          if (element.outlet_id !== "163") {
            dropDownEle = {
              label: element.outlet_name,
              value: element.outlet_id,
            };
            outletOption.push(dropDownEle);
          }
        });

        this.setState({
          outletOption: outletOption,
          outlets: res.data.result_set,
        });
      } else if (res.data.status === "error") {
      }
      $(".dvLoadrCls").fadeOut(500);
      return false;
    });
  }

  getOutletData = () => {
    var outLetLst = this.state.outlets;
    var image_source = webUrl + "/media/dev_team/outlet/";
    if (Object.keys(outLetLst).length > 0) {
      const listhtml = outLetLst.map((item, index) => {
        return item.outlet_id !== "163" ? (
          <li className="outlets-list-column-outlet" key={"contact" + index}>
            <div className="outlet-item-outlet">
              <div className="outlet-item-img-outlet">
                <img src={image_source + item.outlet_image} />
              </div>
              <div className="outlet-item-list-outlet">
                <h3>{stripslashes(item.outlet_name)}</h3>
                <ul>
                  <li>
                    <span>
                      <img className="icon1" src={ct_icon1} />
                      <p>
                        {addressFormat(
                          item.outlet_unit_number1,
                          item.outlet_unit_number2,
                          item.outlet_address_line1,
                          item.outlet_address_line2,
                          item.outlet_postal_code
                        )}
                      </p>
                    </span>
                  </li>
                  <li>{Parser(stripslashes(item.outlet_informations))}</li>
                  <li>
                    <span>
                      <img className="icon2" src={ct_icon2} />
                      <p><a href={this.callPhoneOptn(item.outlet_phone)}>{item.outlet_phone}</a></p>
                    </span>
                  </li>
                  <li>
                    <span>
                      <img className="icon3" src={ct_icon3} />
                      <p>
                        <a
                          className="how-to"
                          href={
                            "https://maps.google.com/maps?ll=" +
                            item.outlet_marker_latitude +
                            "," +
                            item.outlet_marker_longitude +
                            "&q=" +
                            encodeURIComponent(stripslashes(item.outlet_name)) +
                            "&hl=es;output=embed"
                          }
                          target="_blank"
                        >
                          How To Get There!
                        </a>
                      </p>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        ) : (
          ""
        );
      });
      return listhtml;
    } else {
      return "";
    }
  };

  render() {
    return (
      <div>
        <Header />
        <div className=" common-top-div pagesList-main-div">
          <div className="Pages">
            {/* Header start */}

            {/* Header End */}
          </div>
          <div className="common-inner-blckdiv">
            <div className="page-banner">
              <img src={innerBanner} />
              <div className="inner-banner-content">
                <h2>Outlets</h2>
              </div>
            </div>
          </div>

          <div className="container common-top-div pagesList-main-div ">
            <div className="cms-page">
              <div className="container-one cms-content">
                <div className="row-flex">
                  <div className="ct-left">
                    <ul className="outlets-list-row-outlet">{this.getOutletData()}</ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
          <div id="dvLoading" className="dvLoadrCls"></div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

Contact.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Contact)
);
